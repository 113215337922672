import {
  AlignmentType,
  BorderStyle,
  Document,
  ImageRun,
  Packer,
  Paragraph,
  Table,
  TableCell,
  TableLayoutType,
  TableRow,
  TextRun,
  VerticalAlign,
  WidthType,
} from "docx";
import { saveAs } from "file-saver";
import _ from "lodash";

import { Control_Panel_Sizing, Fan_Fit, Product, Quote } from "../generated/graphql";

import { drawFanCurveChart } from "./chart";
import { RPMCoefficient } from "./file-checker";
import { findDesignCoefficients, findDesignPoint, findMaxCFM, generateRPMCurve, generateSystemCurve } from "./fan-curve";
import { calculateAltitudeCorrectionFactor, calculateEfficiency, calculateFLA, calculateFanArrayWeight, calculateMCANew, calculateMOCPNew, calculateOperatingInputHorserpower, calculateOperatingRPM, calculateRPMSoundPower, calculateRedundancy } from "./generate-configuration-query";
import { toast } from 'react-toastify';
import { getPanelAll } from "./calculate-custom-controls";

const default_table_border = {
  bottom: {
    color: "FFFFFF",
    size: 1,
    style: BorderStyle.SINGLE,
  },
  left: {
    color: "FFFFFF",
    size: 0,
    style: BorderStyle.NONE,
  },
  right: {
    color: "FFFFFF",
    size: 1,
    style: BorderStyle.SINGLE,
  },
  top: {
    color: "FFFFFF",
    size: 1,
    style: BorderStyle.SINGLE,
  },
};

const header_table_border = default_table_border;
header_table_border.bottom = {
  color: "FFFFFF",
  size: 3,
  style: BorderStyle.SINGLE
};
header_table_border.top = {
  color: "FFFFFF",
  size: 3,
  style: BorderStyle.SINGLE
};

const generateProductSubmittalElements = ({
  product,
  sizings,
  fanFitData,
}: {
  product: Product,
  sizings: Control_Panel_Sizing[] | undefined;
  fanFitData: Fan_Fit[] | undefined;
}) => {

  const altitudeCorrectionFactor = calculateAltitudeCorrectionFactor(product?.altitude);
  const backdraftDampersCorrectionValue = product?.backdraft_dampers ? 0.2 : 0;
  const adjustedTSP = (product?.total_static_pressure ?? 0) * altitudeCorrectionFactor + backdraftDampersCorrectionValue;

  const fla = calculateFLA(product.configuration?.fla_460, product.voltage_override, product.configuration?.min_v_fla, product.configuration?.max_v_fla);
  const fanCount = product.configured_fan_count ?? 1;

  const airVolumeEach = product.air_flow / fanCount;

  const rawCoefficients = !!product.configuration?.rmp_coefficients
    ? JSON.parse(product.configuration?.rmp_coefficients) : null;

  let coefficients: RPMCoefficient[] = [];

  if (!!rawCoefficients) {
    const RCKeys = Object.keys(rawCoefficients);
    coefficients = RCKeys.map(k => rawCoefficients[k]);
  }

  const unadjustedDesignCoefficients = findDesignCoefficients({
    cfm: product.air_flow,
    tsp: product?.total_static_pressure ?? 0,
    fanCount,
    coefficients,
    model: product.configuration?.model
  });
  const designCoefficients = findDesignCoefficients({
    cfm: product.air_flow,
    tsp: adjustedTSP,
    fanCount,
    coefficients,
    model: product.configuration?.model
  });

  const designRPM = calculateOperatingRPM({
    RPMUpperBound: designCoefficients.upper.rpm,
    RPMLowerBound: designCoefficients.lower.rpm,
    Interpolation: designCoefficients.interpolation
  });
  const horsepower = designCoefficients.upper.coefficient?.powerHp && designCoefficients.lower.coefficient?.powerHp ? calculateOperatingInputHorserpower(
    product.air_flow,
    designCoefficients.fanCount || 0,
    designCoefficients.lower.coefficient?.powerHp,
    designCoefficients.upper.coefficient?.powerHp,
    designCoefficients.interpolation
  ): 0;

  const efficiency = calculateEfficiency({
    fanCount,
    tsp: adjustedTSP,
    cfm: product.air_flow,
    horsepower
  });
  const maxRPM = Math.max(...(Object.keys(rawCoefficients).map((k) => parseInt(k))));
  const maxRPMCoefficients = coefficients.find((c: RPMCoefficient) => c.RPM === maxRPM)
  const maxCFM = maxRPMCoefficients
    ? findMaxCFM({
      tsp: adjustedTSP,
      coefficient: maxRPMCoefficients.pressure!,
    })
    : 0;

  const redundancy = calculateRedundancy({
    fanCount,
    maxCFM: maxCFM,
    designCFM: product.air_flow,
  });

  // Calculate controls weight 
  const mocp = calculateMOCPNew({
    fla: fla, 
    fanCount: product.configured_fan_count ?? 1,
    voltage: product.voltage_override ?? product.set_voltage ?? product.configuration.voltage,
    mas_ec_plus: product.mas_ec_plus,
  });

  const productPanel = getPanelAll(product.configured_fan_count, mocp, sizings, product.mas_ec_plus, product.outdoor_rating);

  const arrayWeight = calculateFanArrayWeight({
    fanCount,
    fanWeight: product.configuration?.fan_weight,
    panelWeight: productPanel.sizing.weight,
    bulkheadHeight: product.height || 0,
    bulkheadWidth: product.width || 0,
  });

  const fullLoadCurrent = product.configuration?.fla_460 ?? product.configuration?.fla_400;
  const soundRPM = !!designCoefficients.upper.coefficient && !!designCoefficients.lower.coefficient
    ? calculateRPMSoundPower(fanCount, product.air_flow / fanCount, designCoefficients.lower.coefficient, designCoefficients.upper.coefficient, designCoefficients.interpolation)
    : null;

  const adjustedCoefficients = coefficients.map(c => Object.assign({}, c, {
    pressure: {
      a: c.pressure.a,
      b: c.pressure.b,
      c: c.pressure.c,
      d: (c.pressure.d/altitudeCorrectionFactor) - backdraftDampersCorrectionValue
    }
  }));

  // RPM curves are adjusted down for altitude
  const rpmCurves = adjustedCoefficients.length
    ? adjustedCoefficients.map(c => generateRPMCurve({ fanCount, rpmCoefficient: c }))
    : [];


  // system & design rpm curve are adjusted up for altitude
  const designPoints = 25;
  const designRPMCurve = !!designRPM
    ? generateRPMCurve({
      fanCount,
      rpmCoefficient: {
        RPM: Math.round(designRPM),
        pressure: unadjustedDesignCoefficients.coefficient,
      } as RPMCoefficient,
      points: designPoints,
      dashed: true
    })
    : null;

  const systemCurve = maxRPMCoefficients ? generateSystemCurve({
    cfm: product.air_flow,
    tsp: product.total_static_pressure,
    fanCount,
    points: designPoints,
    maxRpmPressureCoefficients: maxRPMCoefficients.pressure
  }): null;

  const sets = [...rpmCurves];

  if (designRPMCurve) sets.push(designRPMCurve);
  if (systemCurve) sets.push(systemCurve as any);

  const annotation = { annotations: {} };

  const designPoint = maxRPMCoefficients && systemCurve && findDesignPoint(unadjustedDesignCoefficients.coefficient, systemCurve?.coefficients, product.air_flow, product.total_static_pressure, fanCount);

  if (!!designPoint)
  {
    const annotations = annotation.annotations;

    (annotations as any).designPoint = {
      type: 'point',
      backgroundColor: '#1890cb',
      radius: 5,
      xValue: designPoint.x,
      yValue: designPoint.y
    };
  }

  const fanCurve = drawFanCurveChart(sets, annotation);
  fanCurve.chart.clear();

  const hide63HzRadiatedLabel = product.configuration?.model === "N88-63314";

  // If altitude > 0 we need to add altitude cell between qty and input power
  // But we can't add cells to tables after construction, so we need to do this ugly thing
  let pressureTableLabels = null;
  let pressureTableValues = null;
  if (product.altitude === 0){
    pressureTableLabels = new TableRow({
      children: [
        new TableCell({
          borders: default_table_border,
          shading: {
            fill: 'D8D8D8'
          },
          margins: {left: 0, right: 0},
          width: {
            size: 20,
            type: WidthType.PERCENTAGE
          },
          children: [
            new Paragraph({
              alignment: AlignmentType.CENTER,
              children: [
                new TextRun({
                  text: "Air Volume",
                  font: 'calibri',
                  size: 16,
                  bold: true
                }),
              ]
            }),
            new Paragraph({
              alignment: AlignmentType.CENTER,
              children: [
                new TextRun({
                  text: "(each/total)",
                  font: 'calibri',
                  size: 16,
                  bold: true
                }),
              ]
            })
          ]
        }),
        new TableCell({
          borders: default_table_border,
          shading: {
            fill: 'D8D8D8'
          },
          children: [
            new Paragraph({
              alignment: AlignmentType.CENTER,
              children: [
                new TextRun({
                  text: "Total Static Pressure",
                  font: 'calibri',
                  size: 16,
                  bold: true
                })
              ]
            })
          ]
        }),
        new TableCell({
          borders: default_table_border,
          shading: {
            fill: 'D8D8D8'
          },
          children: [
            new Paragraph({
              alignment: AlignmentType.CENTER,
              children: [
                new TextRun({
                  text: "Quantity of Fans",
                  font: 'calibri',
                  size: 16,
                  bold: true
                })
              ]
            })
          ]
        }),
        new TableCell({
          borders: default_table_border,
          shading: {
            fill: 'D8D8D8'
          },
          children: [
            new Paragraph({
              alignment: AlignmentType.CENTER,
              children: [
                new TextRun({
                  text: "Input Power",
                  font: 'calibri',
                  size: 16,
                  bold: true
                })
              ]
            }),
            new Paragraph({
              alignment: AlignmentType.CENTER,
              children: [
                new TextRun({
                  text: "(each)",
                  font: 'calibri',
                  size: 16,
                  bold: true
                })
              ]
            })
          ]
        }),
        new TableCell({
          borders: default_table_border,
          shading: {
            fill: 'D8D8D8'
          },
          children: [
            new Paragraph({
              alignment: AlignmentType.CENTER,
              children: [
                new TextRun({
                  text: "Operating RPM",
                  font: 'calibri',
                  size: 16,
                  bold: true
                })
              ]
            })
          ]
        }),
        new TableCell({
          borders: default_table_border,
          shading: {
            fill: 'D8D8D8'
          },
          children: [
            new Paragraph({
              alignment: AlignmentType.CENTER,
              children: [
                new TextRun({
                  text: "Fan Weight",
                  font: 'calibri',
                  size: 16,
                  bold: true
                })
              ]
            }),
            new Paragraph({
              alignment: AlignmentType.CENTER,
              children: [
                new TextRun({
                  text: "(each)",
                  font: 'calibri',
                  size: 16,
                  bold: true
                })
              ]
            })
          ]
        })
      ]
    });
    pressureTableValues = new TableRow({
      children: [
        new TableCell({
          borders: default_table_border,
          shading: {
            fill: 'F2F2F2'
          },
          children: [
            new Paragraph({
              alignment: AlignmentType.CENTER,
              children: [
                new TextRun({
                  text: `${parseInt(airVolumeEach.toString())}/${Math.round(product.air_flow ?? 0)} CFM`,
                  font: 'calibri',
                  size: 20,
                })
              ]
            })
          ]
        }),
        new TableCell({
          borders: default_table_border,
          shading: {
            fill: 'F2F2F2'
          },
          children: [
            new Paragraph({
              alignment: AlignmentType.CENTER,
              children: [
                new TextRun({
                  text: `${product.total_static_pressure.toFixed(2)} inWC`,
                  font: 'calibri',
                  size: 20,
                })
              ]
            })
          ]
        }),
        new TableCell({
          borders: default_table_border,
          shading: {
            fill: 'F2F2F2'
          },
          children: [
            new Paragraph({
              alignment: AlignmentType.CENTER,
              children: [
                new TextRun({
                  text: `${fanCount}`,
                  font: 'calibri',
                  size: 20,
                })
              ]
            })
          ]
        }),
        new TableCell({
          borders: default_table_border,
          shading: {
            fill: 'F2F2F2'
          },
          children: [
            new Paragraph({
              alignment: AlignmentType.CENTER,
              children: [
                new TextRun({
                  text: `${horsepower.toFixed(2)} HP`,
                  font: 'calibri',
                  size: 20,
                })
              ]
            })
          ]
        }),
        new TableCell({
          borders: default_table_border,
          shading: {
            fill: 'F2F2F2'
          },
          children: [
            new Paragraph({
              alignment: AlignmentType.CENTER,
              children: [
                new TextRun({
                  text: `${Math.round(designRPM)} rpm`,
                  font: 'calibri',
                  size: 20,
                })
              ]
            })
          ]
        }),
        new TableCell({
          borders: default_table_border,
          shading: {
            fill: 'F2F2F2'
          },
          children: [
            new Paragraph({
              alignment: AlignmentType.CENTER,
              children: [
                new TextRun({
                  text: `${Math.round(product.configuration?.fan_weight)} lb`,
                  font: 'calibri',
                  size: 20,
                })
              ]
            })
          ]
        })
      ]
    })
  } else {
    pressureTableLabels = new TableRow({
      children: [
        new TableCell({
          borders: default_table_border,
          shading: {
            fill: 'D8D8D8'
          },
          margins: {left: 0, right: 0},
          width: {
            size: 20,
            type: WidthType.PERCENTAGE
          },
          children: [
            new Paragraph({
              alignment: AlignmentType.CENTER,
              children: [
                new TextRun({
                  text: "Air Volume",
                  font: 'calibri',
                  size: 16,
                  bold: true
                }),
              ]
            }),
            new Paragraph({
              alignment: AlignmentType.CENTER,
              children: [
                new TextRun({
                  text: "(each/total)",
                  font: 'calibri',
                  size: 16,
                  bold: true
                }),
              ]
            })
          ]
        }),
        new TableCell({
          borders: default_table_border,
          shading: {
            fill: 'D8D8D8'
          },
          children: [
            new Paragraph({
              alignment: AlignmentType.CENTER,
              children: [
                new TextRun({
                  text: "Total Static Pressure",
                  font: 'calibri',
                  size: 16,
                  bold: true
                })
              ]
            })
          ]
        }),
        new TableCell({
          borders: default_table_border,
          shading: {
            fill: 'D8D8D8'
          },
          children: [
            new Paragraph({
              alignment: AlignmentType.CENTER,
              children: [
                new TextRun({
                  text: "Quantity of Fans",
                  font: 'calibri',
                  size: 16,
                  bold: true
                })
              ]
            })
          ]
        }),
        new TableCell({
          borders: default_table_border,
          shading: {
            fill: 'D8D8D8'
          },
          children: [
            new Paragraph({
              alignment: AlignmentType.CENTER,
              children: [
                new TextRun({
                  text: "Altitude",
                  font: 'calibri',
                  size: 16,
                  bold: true
                })
              ]
            })
          ]
        }),
        new TableCell({
          borders: default_table_border,
          shading: {
            fill: 'D8D8D8'
          },
          children: [
            new Paragraph({
              alignment: AlignmentType.CENTER,
              children: [
                new TextRun({
                  text: "Input Power",
                  font: 'calibri',
                  size: 16,
                  bold: true
                })
              ]
            }),
            new Paragraph({
              alignment: AlignmentType.CENTER,
              children: [
                new TextRun({
                  text: "(each)",
                  font: 'calibri',
                  size: 16,
                  bold: true
                })
              ]
            })
          ]
        }),
        new TableCell({
          borders: default_table_border,
          shading: {
            fill: 'D8D8D8'
          },
          children: [
            new Paragraph({
              alignment: AlignmentType.CENTER,
              children: [
                new TextRun({
                  text: "Operating RPM",
                  font: 'calibri',
                  size: 16,
                  bold: true
                })
              ]
            })
          ]
        }),
        new TableCell({
          borders: default_table_border,
          shading: {
            fill: 'D8D8D8'
          },
          children: [
            new Paragraph({
              alignment: AlignmentType.CENTER,
              children: [
                new TextRun({
                  text: "Fan Weight",
                  font: 'calibri',
                  size: 16,
                  bold: true
                })
              ]
            }),
            new Paragraph({
              alignment: AlignmentType.CENTER,
              children: [
                new TextRun({
                  text: "(each)",
                  font: 'calibri',
                  size: 16,
                  bold: true
                })
              ]
            })
          ]
        })
      ]
    });
    pressureTableValues = new TableRow({
      children: [
        new TableCell({
          borders: default_table_border,
          shading: {
            fill: 'F2F2F2'
          },
          children: [
            new Paragraph({
              alignment: AlignmentType.CENTER,
              children: [
                new TextRun({
                  text: `${parseInt(airVolumeEach.toString())}/${Math.round(product.air_flow ?? 0)} CFM`,
                  font: 'calibri',
                  size: 20,
                })
              ]
            })
          ]
        }),
        new TableCell({
          borders: default_table_border,
          shading: {
            fill: 'F2F2F2'
          },
          children: [
            new Paragraph({
              alignment: AlignmentType.CENTER,
              children: [
                new TextRun({
                  text: `${product.total_static_pressure.toFixed(2)} inWC`,
                  font: 'calibri',
                  size: 20,
                })
              ]
            })
          ]
        }),
        new TableCell({
          borders: default_table_border,
          shading: {
            fill: 'F2F2F2'
          },
          children: [
            new Paragraph({
              alignment: AlignmentType.CENTER,
              children: [
                new TextRun({
                  text: `${fanCount}`,
                  font: 'calibri',
                  size: 20,
                })
              ]
            })
          ]
        }),
        new TableCell({
          borders: default_table_border,
          shading: {
            fill: 'F2F2F2'
          },
          children: [
            new Paragraph({
              alignment: AlignmentType.CENTER,
              children: [
                new TextRun({
                  text: `${product.altitude} ft`,
                  font: 'calibri',
                  size: 20,
                })
              ]
            })
          ]
        }),
        new TableCell({
          borders: default_table_border,
          shading: {
            fill: 'F2F2F2'
          },
          children: [
            new Paragraph({
              alignment: AlignmentType.CENTER,
              children: [
                new TextRun({
                  text: `${horsepower.toFixed(2)} HP`,
                  font: 'calibri',
                  size: 20,
                })
              ]
            })
          ]
        }),
        new TableCell({
          borders: default_table_border,
          shading: {
            fill: 'F2F2F2'
          },
          children: [
            new Paragraph({
              alignment: AlignmentType.CENTER,
              children: [
                new TextRun({
                  text: `${Math.round(designRPM)} rpm`,
                  font: 'calibri',
                  size: 20,
                })
              ]
            })
          ]
        }),
        new TableCell({
          borders: default_table_border,
          shading: {
            fill: 'F2F2F2'
          },
          children: [
            new Paragraph({
              alignment: AlignmentType.CENTER,
              children: [
                new TextRun({
                  text: `${Math.round(product.configuration?.fan_weight)} lb`,
                  font: 'calibri',
                  size: 20,
                })
              ]
            })
          ]
        })
      ]
    })
  }

  const pressureTable = new Table({
    width: {
      size: 100,
      type: WidthType.PERCENTAGE,
    },
    borders: {
      left: {
        style: BorderStyle.NONE
      },
      right: {
        style: BorderStyle.NONE
      }
    },
    margins: {
      left: 0,
      right: 0
    },
    layout: TableLayoutType.FIXED,
    rows: [
      pressureTableLabels,
      pressureTableValues
    ]
  });

  const efficiencyTable = new Table({
    width: {
      size: 100,
      type: WidthType.PERCENTAGE,
    },
    rows: [
      new TableRow({
        children: [
          new TableCell({
            borders: default_table_border,
            shading: {
              fill: 'D8D8D8'
            },
            children: [
              new Paragraph({
                alignment: AlignmentType.CENTER,
                children: [
                  new TextRun({
                    text: "Fan Model",
                    font: 'calibri',
                    size: 16,
                    bold: true
                  })
                ]
              })]
          }),
          new TableCell({
            borders: default_table_border,
            shading: {
              fill: 'D8D8D8'
            },
            children: [
              new Paragraph({
                alignment: AlignmentType.CENTER,
                children: [
                  new TextRun({
                    text: "Material",
                    font: 'calibri',
                    size: 16,
                    bold: true
                  })
                ]
              })
            ]
          }),
          new TableCell({
            borders: default_table_border,
            shading: {
              fill: 'D8D8D8'
            },
            children: [
              new Paragraph({
                alignment: AlignmentType.CENTER,
                children: [
                  new TextRun({
                    text: "Efficiency",
                    font: 'calibri',
                    size: 16,
                    bold: true
                  })
                ]
              })]
          }),
          new TableCell({
            borders: default_table_border,
            shading: {
              fill: 'D8D8D8'
            },
            children: [
              new Paragraph({
                alignment: AlignmentType.CENTER,
                children: [
                  new TextRun({
                    text: "Redundancy",
                    font: 'calibri',
                    size: 16,
                    bold: true
                  })
                ]
              })]
          }),
          new TableCell({
            borders: default_table_border,
            shading: {
              fill: 'D8D8D8'
            },
            children: [
              new Paragraph({
                alignment: AlignmentType.CENTER,
                children: [
                  new TextRun({
                    text: "Array Weight",
                    font: 'calibri',
                    size: 16,
                    bold: true
                  })
                ]
              })
            ]
          })
        ]
      }),
      new TableRow({
        children: [
          new TableCell({
            borders: default_table_border,
            shading: {
              fill: 'F2F2F2'
            },
            children: [
              new Paragraph({
                alignment: AlignmentType.CENTER,
                children: [
                  new TextRun({
                    text: `${product.configuration?.model}`,
                    font: 'calibri',
                    size: 20
                  })
                ]
              })
            ]
          }),
          new TableCell({
            borders: default_table_border,
            shading: {
              fill: 'F2F2F2'
            },
            children: [
              new Paragraph({
                alignment: AlignmentType.CENTER,
                children: [
                  new TextRun({
                    text: product.configuration?.blade_material ?? '',
                    font: 'calibri',
                    size: 20,
                  })
                ]
              })
            ]
          }),
          new TableCell({
            borders: default_table_border,
            shading: {
              fill: 'F2F2F2'
            },
            children: [
              new Paragraph({
                alignment: AlignmentType.CENTER,
                children: [
                  new TextRun({
                    text: `${(efficiency * 100).toFixed(2)} %`,
                    font: 'calibri',
                    size: 20
                  })
                ]
              })
            ]
          }),
          new TableCell({
            borders: default_table_border,
            shading: {
              fill: 'F2F2F2'
            },
            children: [
              new Paragraph({
                alignment: AlignmentType.CENTER,
                children: [
                  new TextRun({
                    text: `${Math.round(Math.max(0.01, (redundancy * 100)))} %`,
                    font: 'calibri',
                    size: 20
                  })
                ]
              })
            ]
          }),
          new TableCell({
            borders: default_table_border,
            shading: {
              fill: 'F2F2F2'
            },
            children: [
              new Paragraph({
                alignment: AlignmentType.CENTER,
                children: [
                  new TextRun({
                    text: `${Math.round(arrayWeight)} lb`,
                    font: 'calibri',
                    size: 20
                  })
                ]
              })
            ]
          })
        ]
      })
    ]
  });

  const motorElectricalTable = new Table({
    width: {
      size: 100,
      type: WidthType.PERCENTAGE,
    },
    rows: [
      new TableRow({
        children: [
          new TableCell({
            borders: default_table_border,
            shading: {
              fill: 'D8D8D8'
            },
            width: {
              size: 1/6,
              type: WidthType.PERCENTAGE
            },
            children: [
              new Paragraph({
                alignment: AlignmentType.CENTER,
                children: [
                  new TextRun({
                    text: "Motor Horsepower",
                    font: 'calibri',
                    size: 16,
                    bold: true
                  })
                ]
              }),
              new Paragraph({
                alignment: AlignmentType.CENTER,
                children: [
                  new TextRun({
                    text: "(each)",
                    font: 'calibri',
                    size: 16,
                    bold: true
                  })
                ]
              })
            ]
          }),
          new TableCell({
            borders: default_table_border,
            shading: {
              fill: 'D8D8D8'
            },
            width: {
              size: 1/6,
              type: WidthType.PERCENTAGE
            },
            children: [
              new Paragraph({
                alignment: AlignmentType.CENTER,
                children: [
                  new TextRun({
                    text: "Maximum RPM",
                    font: 'calibri',
                    size: 16,
                    bold: true
                  })
                ]
              })
            ]
          }),
          new TableCell({
            borders: default_table_border,
            shading: {
              fill: 'D8D8D8'
            },
            width: {
              size: 1/6,
              type: WidthType.PERCENTAGE
            },
            children: [
              new Paragraph({
                alignment: AlignmentType.CENTER,
                children: [
                  new TextRun({
                    text: "Electrical Supply",
                    font: 'calibri',
                    size: 16,
                    bold: true
                  })
                ]
              })
            ]
          }),
          new TableCell({
            borders: default_table_border,
            shading: {
              fill: 'D8D8D8'
            },
            width: {
              size: 1/6,
              type: WidthType.PERCENTAGE
            },
            children: [
              new Paragraph({
                alignment: AlignmentType.CENTER,
                children: [
                  new TextRun({
                    text: "Full Load Current",
                    font: 'calibri',
                    size: 16,
                    bold: true
                  })
                ]
              }),
              new Paragraph({
                alignment: AlignmentType.CENTER,
                children: [
                  new TextRun({
                    text: "(each)",
                    font: 'calibri',
                    size: 16,
                    bold: true
                  })
                ]
              })
            ]
          }),
          new TableCell({
            borders: default_table_border,
            shading: {
              fill: 'D8D8D8'
            },
            width: {
              size: 1/6,
              type: WidthType.PERCENTAGE
            },
            children: [
              new Paragraph({
                alignment: AlignmentType.CENTER,
                children: [
                  new TextRun({
                    text: "MCA",
                    font: 'calibri',
                    size: 16,
                    bold: true
                  })
                ]
              })
            ]
          }),
          new TableCell({
            borders: default_table_border,
            shading: {
              fill: 'D8D8D8'
            },
            width: {
              size: 1/6,
              type: WidthType.PERCENTAGE
            },
            children: [
              new Paragraph({
                alignment: AlignmentType.CENTER,
                children: [
                  new TextRun({
                    text: "MOCP",
                    font: 'calibri',
                    size: 16,
                    bold: true
                  })
                ]
              })
            ]
          })
        ]
      }),
      new TableRow({
        children: [
          new TableCell({
            borders: default_table_border,
            shading: {
              fill: 'F2F2F2'
            },
            children: [
              new Paragraph({
                alignment: AlignmentType.CENTER,
                children: [
                  new TextRun({
                    text: `${product.configuration?.nominal_hp} HP`,
                    font: 'calibri',
                    size: 20,
                  })
                ]
              })
            ]
          }),
          new TableCell({
            borders: default_table_border,
            shading: {
              fill: 'F2F2F2'
            },
            children: [
              new Paragraph({
                alignment: AlignmentType.CENTER,
                children: [
                  new TextRun({
                    text: `${maxRPM} rpm`,
                    font: 'calibri',
                    size: 20,
                  })
                ]
              })
            ]
          }),
          new TableCell({
            borders: default_table_border,
            shading: {
              fill: 'F2F2F2'
            },
            children: [
              new Paragraph({
                alignment: AlignmentType.CENTER,
                children: [
                  new TextRun({
                    text: `${product.voltage_override ?? product.configuration?.voltage} V/3ph`,
                    font: 'calibri',
                    size: 20,
                  })
                ]
              })
            ]
          }),
          new TableCell({
            borders: default_table_border,
            shading: {
              fill: 'F2F2F2'
            },
            children: [
              new Paragraph({
                alignment: AlignmentType.CENTER,
                children: [
                  new TextRun({
                    text: `${fullLoadCurrent} A`,
                    font: 'calibri',
                    size: 20,
                  })
                ]
              })
            ]
          }),
          new TableCell({
            borders: default_table_border,
            shading: {
              fill: 'F2F2F2'
            },
            children: [
              new Paragraph({
                alignment: AlignmentType.CENTER,
                children: [
                  new TextRun({
                    text: `${calculateMCANew({ 
                      fla: fla, 
                      fanCount: fanCount, 
                      voltage: product.voltage_override ?? product.set_voltage ?? product.configuration.voltage, 
                      mas_ec_plus: product.mas_ec_plus
                    }).toFixed(2)} A`,
                    font: 'calibri',
                    size: 20,
                  })
                ]
              })
            ]
          }),
          new TableCell({
            borders: default_table_border,
            shading: {
              fill: 'F2F2F2'
            },
            children: [
              new Paragraph({
                alignment: AlignmentType.CENTER,
                children: [
                  new TextRun({
                    text: `${Math.round(mocp)} A`,
                    font: 'calibri',
                    size: 20,
                  })
                ]
              })
            ]
          })
        ]
      })
    ]
  });

  // If this is a mas_ec_plus product, need to add extra row to show indoor/outdoor rating
  // So we build rows that are always required first
  let blankOffRows = [
    new TableRow({
      children: [
        new TableCell({
          borders: default_table_border,
          shading: {
            fill: 'D8D8D8'
          },
          children: [
            new Paragraph({
              alignment: AlignmentType.CENTER,
              children: [
                new TextRun({
                  text: "Blank-Off Plate:",
                  font: 'calibri',
                  size: 16,
                  bold: true
                })
              ]
            })
          ]
        }),
        new TableCell({
          borders: default_table_border,
          shading: {
            fill: 'F2F2F2'
          },
          children: [
            new Paragraph({
              alignment: AlignmentType.CENTER,
              children: [
                new TextRun({
                  text: product.backdraft_dampers ? 'None' : 'Included',
                  font: 'calibri',
                  size: 20,
                })
              ]
            })
          ]
        }),
      ]
    }),
    new TableRow({
      children: [
        new TableCell({
          borders: default_table_border,
          shading: {
            fill: 'D8D8D8'
          },
          children: [
            new Paragraph({
              alignment: AlignmentType.CENTER,
              children: [
                new TextRun({
                  text: "Fan Panel Disconnect:",
                  font: 'calibri',
                  size: 16,
                  bold: true
                })
              ]
            })
          ]
        }),
        new TableCell({
          borders: default_table_border,
          shading: {
            fill: 'F2F2F2'
          },
          children: [
            new Paragraph({
              alignment: AlignmentType.CENTER,
              children: [
                new TextRun({
                  text: "100kAIC Fused Disconnect",
                  font: 'calibri',
                  size: 20,
                })
              ]
            })
          ]
        }),
      ]
    })
  ];
  let isolationRows = [
    new TableRow({
      children: [
        new TableCell({
          borders: default_table_border,
          shading: {
            fill: 'D8D8D8'
          },
          children: [
            new Paragraph({
              alignment: AlignmentType.CENTER,
              children: [
                new TextRun({
                  text: "Isolation Backdraft Dampers:",
                  font: 'calibri',
                  size: 16,
                  bold: true
                })
              ]
            })
          ]
        }),
        new TableCell({
          borders: default_table_border,
          shading: {
            fill: 'F2F2F2'
          },
          children: [
            new Paragraph({
              alignment: AlignmentType.CENTER,
              children: [
                new TextRun({
                  text: product.backdraft_dampers ? 'Included' : 'None',
                  font: 'calibri',
                  size: 20,
                })
              ]
            })
          ]
        })
      ]
    }),
    new TableRow({
      children: [
        new TableCell({
          borders: default_table_border,
          shading: {
            fill: 'D8D8D8'
          },
          children: [
            new Paragraph({
              alignment: AlignmentType.CENTER,
              children: [
                new TextRun({
                  text: "Control Input:",
                  font: 'calibri',
                  size: 16,
                  bold: true
                })
              ]
            })
          ]
        }),
        new TableCell({
          borders: default_table_border,
          shading: {
            fill: 'F2F2F2'
          },
          children: [
            new Paragraph({
              alignment: AlignmentType.CENTER,
              children: [
                new TextRun({
                  text: product.mas_ec_plus ? "BACnet Controller" : "HOA only (0-10 VDC)",
                  font: 'calibri',
                  size: 20,
                })
              ]
            })
          ]
        })
      ]
    })
  ];

  // Then add indoor/outdoor if necessary
  if (product.mas_ec_plus) {
    blankOffRows.push(
      new TableRow({
        children: [
          new TableCell({
            borders: default_table_border,
            shading: {
              fill: 'D8D8D8'
            },
            children: [
              new Paragraph({
                alignment: AlignmentType.CENTER,
                children: [
                  new TextRun({
                    text: "Control Panel Rating",
                    font: 'calibri',
                    size: 16,
                    bold: true
                  })
                ]
              })
            ]
          }),
          new TableCell({
            borders: default_table_border,
            shading: {
              fill: 'F2F2F2'
            },
            children: [
              new Paragraph({
                alignment: AlignmentType.CENTER,
                children: [
                  new TextRun({
                    text: (product.mas_ec_plus && !product.outdoor_rating) ? 'NEMA 1 (Indoor)' : 'NEMA 4 (Outdoor)',
                    font: 'calibri',
                    size: 20,
                  })
                ]
              })
            ]
          }),
        ]
      })
    );
    isolationRows.push(
      new TableRow({
        children: [
          new TableCell({
            borders: default_table_border,
            shading: {
              fill: 'D8D8D8'
            },
            children: [
              new Paragraph({
                alignment: AlignmentType.CENTER,
                children: [
                  new TextRun({
                    text: "",
                    font: 'calibri',
                    size: 16,
                    bold: true
                  })
                ]
              })
            ]
          }),
          new TableCell({
            borders: default_table_border,
            shading: {
              fill: 'F2F2F2'
            },
            children: [
              new Paragraph({
                alignment: AlignmentType.CENTER,
                children: [
                  new TextRun({
                    text: "",
                    font: 'calibri',
                    size: 20,
                  })
                ]
              })
            ]
          }),
        ]
      })
    );
  };

  const blankOffTable = new Table({
    width: {
      size: 100,
      type: WidthType.PERCENTAGE,
    },
    rows: blankOffRows,
  });

  const isolationTable = new Table({
    width: {
      size: 100,
      type: WidthType.PERCENTAGE,
    },
    rows: isolationRows,
  });

  return [
    {
      children: [
        new Paragraph({
          text: `Product: ${product.tag}`,
        }),
        new Paragraph({
          children: [],  // Just newline without text
        }),
        new Paragraph({
          children: [],  // Just newline without text
        }),
        new Table({
          width: {
            size: 101.215,
            type: WidthType.PERCENTAGE,
          },
          alignment: AlignmentType.CENTER,
          rows: [
            new TableRow({
              children: [
                new TableCell({
                  borders: header_table_border,
                  shading: {
                    fill: '1A4061'
                  },
                  children: [
                    new Paragraph({
                      alignment: AlignmentType.LEFT,
                      indent: {
                        left: 64
                      },
                      children: [
                        new TextRun({
                          color: 'FFFFFF',
                          text: "ECM Fan Array",
                          font: 'calibri',
                          size: 20,
                          bold: true,
                        })
                      ]
                    })
                  ]
                }),
                new TableCell({
                  borders: header_table_border,
                  shading: {
                    fill: '1A4061'
                  },
                  margins: {
                    right: 32 
                  },
                  verticalAlign: VerticalAlign.CENTER,
                  children: [
                    new Paragraph({
                      alignment: AlignmentType.LEFT,
                      indent: {
                        left: 64
                      },
                      children: [
                        new TextRun({
                          color: 'FFFFFF',
                          text: "Component:",
                          font: 'calibri',
                          size: 16,
                        })
                      ]
                    })
                  ]
                }),
                new TableCell({
                  borders: header_table_border,
                  shading: {
                    fill: '1A4061'
                  },
                  verticalAlign: VerticalAlign.CENTER,
                  children: [
                    new Paragraph({
                      alignment: AlignmentType.LEFT,
                      indent: {
                        left: 64
                      },
                      children: [
                        new TextRun({
                          color: 'FFFFFF',
                          text: "Length:",
                          font: 'calibri',
                          size: 16,
                        })
                      ]
                    })
                  ]
                }),
                new TableCell({
                  borders: header_table_border,
                  shading: {
                    fill: '1A4061'
                  },
                  verticalAlign: VerticalAlign.CENTER,
                  children: [
                    new Paragraph({
                      alignment: AlignmentType.LEFT,
                      indent: {
                        left: 64
                      },
                      children: [
                        new TextRun({
                          color: 'FFFFFF',
                          text: "Shipping Section:",
                          font: 'calibri',
                          size: 16,
                        })
                      ]
                    })
                  ]
                }),
              ]
            }),
            new TableRow({
              children: [
                new TableCell({
                  borders: header_table_border,
                  shading: {
                    fill: 'B8CCE4'
                  },
                  width: {
                    size: 100,
                    type: WidthType.NIL
                  },
                  columnSpan: 4,
                  children: [
                    new Paragraph({
                      alignment: AlignmentType.CENTER,
                      children: [
                        new TextRun({
                          text: "Fan Data",
                          font: 'calibri',
                          size: 16,
                          bold: true
                        })
                      ]
                    })
                  ]
                })
              ]
            }),
            new TableRow({
              children: [
                new TableCell({
                  margins: {
                    left: 0,
                    right: 0
                  },
                  borders: default_table_border,
                  columnSpan: 4,
                  children: [pressureTable]
                })
              ]
            }),
            new TableRow({
              children: [
                new TableCell({
                  margins: {
                    left: 0,
                    right: 0
                  },
                  borders: default_table_border,
                  columnSpan: 4,
                  children: [efficiencyTable]
                })
              ]
            }),
            new TableRow({
              children: [
                new TableCell({
                  borders: header_table_border,
                  shading: {
                    fill: 'B8CCE4'
                  },
                  columnSpan: 4,
                  margins: {
                    left: 0,
                    right: 0
                  },
                  children: [
                    new Paragraph({
                      alignment: AlignmentType.CENTER,
                      children: [
                        new TextRun({
                          text: "Motor/Electrical Data",
                          font: 'calibri',
                          size: 16,
                          bold: true
                        })
                      ]
                    })
                  ]
                })
              ]
            }),
            new TableRow({
              children: [
                new TableCell({
                  margins: {
                    left: 0,
                    right: 0
                  },
                  borders: default_table_border,
                  columnSpan: 4,
                  children: [motorElectricalTable]
                })
              ]
            }),
            new TableRow({
              children: [
                new TableCell({
                  borders: header_table_border,
                  shading: {
                    fill: 'B8CCE4'
                  },
                  columnSpan: 4,
                  margins: {
                    left: 0,
                    right: 0
                  },
                  children: [
                    new Paragraph({
                      alignment: AlignmentType.CENTER,
                      children: [
                        new TextRun({
                          text: "Fan Options",
                          font: 'calibri',
                          size: 16,
                          bold: true
                        })
                      ]
                    })
                  ]
                })
              ]
            }),
            new TableRow({
              children: [
                new TableCell({
                  borders: default_table_border,
                  columnSpan: 2,
                  margins: {
                    left: 0,
                    right: 0
                  },
                  children: [blankOffTable]
                }),
                new TableCell({
                  borders: default_table_border,
                  columnSpan: 2,
                  margins: {
                    left: 0,
                    right: 0
                  },
                  children: [isolationTable]
                })
              ]
            }),
          ]
        }),
        new Paragraph({
          spacing: {
            before: 20,
            after: 20
          },
          alignment: AlignmentType.CENTER,
          children: [
            new ImageRun({
              data: fanCurve.image,
              transformation: {
                width: 610,
                height: 300,
              },
              altText: {
                name: "Fan Curve Chart",
                title: "Fan Curve Chart",
                description: "Fan Curve Chart",
              },
            }),
          ],
        }),
        new Table({
          width: {
            size: 101.25,
            type: WidthType.PERCENTAGE,
          },
          alignment: AlignmentType.CENTER,
          rows: [
            new TableRow({
              children: [
                new TableCell({
                  borders: default_table_border,
                  shading: {
                    fill: '1A4061'
                  },
                  columnSpan: 9,
                  children: [
                    new Paragraph({
                      alignment: AlignmentType.CENTER,
                      children: [
                        new TextRun({
                          color: 'FFFFFF',
                          text: "Array Sound Power (dB)",
                          font: 'calibri',
                          size: 20,
                          bold: true
                        })
                      ]
                    })
                  ]
                })
              ]
            }),
            new TableRow({
              children: [
                new TableCell({
                  borders: default_table_border,
                  shading: {
                    fill: 'D8D8D8'
                  },
                  verticalAlign: VerticalAlign.CENTER,
                  children: [
                    new Paragraph({
                      alignment: AlignmentType.CENTER,
                      children: [
                        new TextRun({
                          text: "Type",
                          font: 'calibri',
                          size: 16,
                          bold: true
                        })
                      ]
                    })
                  ],
                }),
                new TableCell({
                  borders: default_table_border,
                  shading: {
                    fill: 'D8D8D8'
                  },
                  verticalAlign: VerticalAlign.CENTER,
                  children: [
                    new Paragraph({
                      alignment: AlignmentType.CENTER,
                      children: [
                        new TextRun({
                          text: "63 Hz",
                          font: 'calibri',
                          size: 16,
                          bold: true
                        })
                      ]
                    })
                  ],
                }),
                new TableCell({
                  borders: default_table_border,
                  shading: {
                    fill: 'D8D8D8'
                  },
                  verticalAlign: VerticalAlign.CENTER,
                  children: [
                    new Paragraph({
                      alignment: AlignmentType.CENTER,
                      children: [
                        new TextRun({
                          text: "125 Hz",
                          font: 'calibri',
                          size: 16,
                          bold: true
                        })
                      ]
                    })
                  ],
                }),
                new TableCell({
                  borders: default_table_border,
                  shading: {
                    fill: 'D8D8D8'
                  },
                  verticalAlign: VerticalAlign.CENTER,
                  children: [
                    new Paragraph({
                      alignment: AlignmentType.CENTER,
                      children: [
                        new TextRun({
                          text: "250 Hz",
                          font: 'calibri',
                          size: 16,
                          bold: true
                        })
                      ]
                    })
                  ],
                }),
                new TableCell({
                  borders: default_table_border,
                  shading: {
                    fill: 'D8D8D8'
                  },
                  verticalAlign: VerticalAlign.CENTER,
                  children: [
                    new Paragraph({
                      alignment: AlignmentType.CENTER,
                      children: [
                        new TextRun({
                          text: "500 Hz",
                          font: 'calibri',
                          size: 16,
                          bold: true,
                        })
                      ]
                    })
                  ],
                }),
                new TableCell({
                  borders: default_table_border,
                  shading: {
                    fill: 'D8D8D8'
                  },
                  verticalAlign: VerticalAlign.CENTER,
                  children: [
                    new Paragraph({
                      alignment: AlignmentType.CENTER,
                      children: [
                        new TextRun({
                          text: "1000 Hz",
                          font: 'calibri',
                          size: 16,
                          bold: true
                        })
                      ]
                    })
                  ],
                }),
                new TableCell({
                  borders: default_table_border,
                  shading: {
                    fill: 'D8D8D8'
                  },
                  verticalAlign: VerticalAlign.CENTER,
                  children: [
                    new Paragraph({
                      alignment: AlignmentType.CENTER,
                      children: [
                        new TextRun({
                          text: "2000 Hz",
                          font: 'calibri',
                          size: 16,
                          bold: true
                        })
                      ]
                    })
                  ],
                }),
                new TableCell({
                  borders: default_table_border,
                  shading: {
                    fill: 'D8D8D8'
                  },
                  verticalAlign: VerticalAlign.CENTER,
                  children: [
                    new Paragraph({
                      alignment: AlignmentType.CENTER,
                      children: [
                        new TextRun({
                          text: "4000 Hz",
                          font: 'calibri',
                          size: 16,
                          bold: true
                        })
                      ]
                    })
                  ],
                }),
                new TableCell({
                  borders: default_table_border,
                  shading: {
                    fill: 'D8D8D8'
                  },
                  verticalAlign: VerticalAlign.CENTER,
                  children: [
                    new Paragraph({
                      alignment: AlignmentType.CENTER,
                      children: [
                        new TextRun({
                          text: "8000 Hz",
                          font: 'calibri',
                          size: 16,
                          bold: true
                        })
                      ]
                    })
                  ],
                }),
              ],
            }),
            new TableRow({
              children: [
                new TableCell({
                  borders: default_table_border,
                  shading: {
                    fill: 'D8D8D8'
                  },
                  verticalAlign: VerticalAlign.CENTER,
                  children: [
                    new Paragraph({
                      alignment: AlignmentType.CENTER,
                      children: [
                        new TextRun({
                          text: "Radiated:",
                          font: 'calibri',
                          size: 16,
                          bold: true
                        })
                      ]
                    })
                  ],
                }),
                new TableCell({
                  borders: default_table_border,
                  shading: {
                    fill: 'F2F2F2'
                  },
                  children: [
                    new Paragraph({
                      alignment: AlignmentType.CENTER,
                      children: [
                        new TextRun({
                          text: `${hide63HzRadiatedLabel ? "-" : Math.round(soundRPM?.hz63.radiated ?? 0)}`,
                          font: 'calibri',
                          size: 20,
                        })
                      ]
                    })
                  ],
                }),
                new TableCell({
                  borders: default_table_border,
                  shading: {
                    fill: 'F2F2F2'
                  },
                  children: [
                    new Paragraph({
                      alignment: AlignmentType.CENTER,
                      children: [
                        new TextRun({
                          text: `${Math.round(soundRPM?.hz125.radiated ?? 0)}`,
                          font: 'calibri',
                          size: 20,
                        })
                      ]
                    })
                  ],
                }),
                new TableCell({
                  borders: default_table_border,
                  shading: {
                    fill: 'F2F2F2'
                  },
                  children: [
                    new Paragraph({
                      alignment: AlignmentType.CENTER,
                      children: [
                        new TextRun({
                          text: `${Math.round(soundRPM?.hz250.radiated ?? 0)}`,
                          font: 'calibri',
                          size: 20,
                        })
                      ]
                    })
                  ],
                }),
                new TableCell({
                  borders: default_table_border,
                  shading: {
                    fill: 'F2F2F2'
                  },
                  children: [
                    new Paragraph({
                      alignment: AlignmentType.CENTER,
                      children: [
                        new TextRun({
                          text: `${Math.round(soundRPM?.hz500.radiated ?? 0)}`,
                          font: 'calibri',
                          size: 20,
                        })
                      ]
                    })
                  ],
                }),
                new TableCell({
                  borders: default_table_border,
                  shading: {
                    fill: 'F2F2F2'
                  },
                  children: [
                    new Paragraph({
                      alignment: AlignmentType.CENTER,
                      children: [
                        new TextRun({
                          text: `${Math.round(soundRPM?.hz1000.radiated ?? 0)}`,
                          font: 'calibri',
                          size: 20,
                        })
                      ]
                    })
                  ],
                }),
                new TableCell({
                  borders: default_table_border,
                  shading: {
                    fill: 'F2F2F2'
                  },
                  children: [
                    new Paragraph({
                      alignment: AlignmentType.CENTER,
                      children: [
                        new TextRun({
                          text: `${Math.round(soundRPM?.hz2000.radiated ?? 0)}`,
                          font: 'calibri',
                          size: 20,
                        })
                      ]
                    })
                  ],
                }),
                new TableCell({
                  borders: default_table_border,
                  shading: {
                    fill: 'F2F2F2'
                  },
                  children: [
                    new Paragraph({
                      alignment: AlignmentType.CENTER,
                      children: [
                        new TextRun({
                          text: `${Math.round(soundRPM?.hz4000.radiated ?? 0)}`,
                          font: 'calibri',
                          size: 20,
                        })
                      ]
                    })
                  ],
                }),
                new TableCell({
                  borders: default_table_border,
                  shading: {
                    fill: 'F2F2F2'
                  },
                  children: [
                    new Paragraph({
                      alignment: AlignmentType.CENTER,
                      children: [
                        new TextRun({
                          text: `${Math.round(soundRPM?.hz8000.radiated ?? 0)}`,
                          font: 'calibri',
                          size: 20,
                        })
                      ]
                    })
                  ],
                }),
              ],
            }),
            new TableRow({
              children: [
                new TableCell({
                  borders: default_table_border,
                  shading: {
                    fill: 'D8D8D8'
                  },
                  verticalAlign: VerticalAlign.CENTER,
                  children: [
                    new Paragraph({
                      alignment: AlignmentType.CENTER,
                      children: [
                        new TextRun({
                          text: "Unit Discharge:",
                          font: 'calibri',
                          size: 16,
                          bold: true
                        })
                      ]
                    })
                  ],
                }),
                new TableCell({
                  borders: default_table_border,
                  shading: {
                    fill: 'F2F2F2'
                  },
                  children: [
                    new Paragraph({
                      alignment: AlignmentType.CENTER,
                      children: [
                        new TextRun({
                          text: `${hide63HzRadiatedLabel ? "-" : Math.round(soundRPM?.hz63.unitDischarge ?? 0)}`,
                          font: 'calibri',
                          size: 20,
                        })
                      ]
                    })
                  ],
                }),
                new TableCell({
                  borders: default_table_border,
                  shading: {
                    fill: 'F2F2F2'
                  },
                  children: [
                    new Paragraph({
                      alignment: AlignmentType.CENTER,
                      children: [
                        new TextRun({
                          text: `${Math.round(soundRPM?.hz125.unitDischarge ?? 0)}`,
                          font: 'calibri',
                          size: 20,
                        })
                      ]
                    })
                  ],
                }),
                new TableCell({
                  borders: default_table_border,
                  shading: {
                    fill: 'F2F2F2'
                  },
                  children: [
                    new Paragraph({
                      alignment: AlignmentType.CENTER,
                      children: [
                        new TextRun({
                          text: `${Math.round(soundRPM?.hz250.unitDischarge ?? 0)}`,
                          font: 'calibri',
                          size: 20,
                        })
                      ]
                    })
                  ],
                }),
                new TableCell({
                  borders: default_table_border,
                  shading: {
                    fill: 'F2F2F2'
                  },
                  children: [
                    new Paragraph({
                      alignment: AlignmentType.CENTER,
                      children: [
                        new TextRun({
                          text: `${Math.round(soundRPM?.hz500.unitDischarge ?? 0)}`,
                          font: 'calibri',
                          size: 20,
                        })
                      ]
                    })
                  ],
                }),
                new TableCell({
                  borders: default_table_border,
                  shading: {
                    fill: 'F2F2F2'
                  },
                  children: [
                    new Paragraph({
                      alignment: AlignmentType.CENTER,
                      children: [
                        new TextRun({
                          text: `${Math.round(soundRPM?.hz1000.unitDischarge ?? 0)}`,
                          font: 'calibri',
                          size: 20,
                        })
                      ]
                    })
                  ],
                }),
                new TableCell({
                  borders: default_table_border,
                  shading: {
                    fill: 'F2F2F2'
                  },
                  children: [
                    new Paragraph({
                      alignment: AlignmentType.CENTER,
                      children: [
                        new TextRun({
                          text: `${Math.round(soundRPM?.hz2000.unitDischarge ?? 0)}`,
                          font: 'calibri',
                          size: 20,
                        })
                      ]
                    })
                  ],
                }),
                new TableCell({
                  borders: default_table_border,
                  shading: {
                    fill: 'F2F2F2'
                  },
                  children: [
                    new Paragraph({
                      alignment: AlignmentType.CENTER,
                      children: [
                        new TextRun({
                          text: `${Math.round(soundRPM?.hz4000.unitDischarge ?? 0)}`,
                          font: 'calibri',
                          size: 20,
                        })
                      ]
                    })
                  ],
                }),
                new TableCell({
                  borders: default_table_border,
                  shading: {
                    fill: 'F2F2F2'
                  },
                  children: [
                    new Paragraph({
                      alignment: AlignmentType.CENTER,
                      children: [
                        new TextRun({
                          text: `${Math.round(soundRPM?.hz8000.unitDischarge ?? 0)}`,
                          font: 'calibri',
                          size: 20,
                        })
                      ]
                    })
                  ],
                }),
              ],
            }),
            new TableRow({
              children: [
                new TableCell({
                  borders: default_table_border,
                  shading: {
                    fill: 'D8D8D8'
                  },
                  verticalAlign: VerticalAlign.CENTER,
                  children: [
                    new Paragraph({
                      alignment: AlignmentType.CENTER,
                      children: [
                        new TextRun({
                          text: "Unit Return:",
                          font: 'calibri',
                          size: 16,
                          bold: true
                        })
                      ]
                    })
                  ],
                }),
                new TableCell({
                  borders: default_table_border,
                  shading: {
                    fill: 'F2F2F2'
                  },
                  children: [
                    new Paragraph({
                      alignment: AlignmentType.CENTER,
                      children: [
                        new TextRun({
                          text: `${hide63HzRadiatedLabel ? "-" : Math.round(soundRPM?.hz63.unitReturn ?? 0)}`,
                          font: 'calibri',
                          size: 20,
                        })
                      ]
                    })
                  ],
                }),
                new TableCell({
                  borders: default_table_border,
                  shading: {
                    fill: 'F2F2F2'
                  },
                  children: [
                    new Paragraph({
                      alignment: AlignmentType.CENTER,
                      children: [
                        new TextRun({
                          text: `${Math.round(soundRPM?.hz125.unitReturn ?? 0)}`,
                          font: 'calibri',
                          size: 20,
                        })
                      ]
                    })
                  ],
                }),
                new TableCell({
                  borders: default_table_border,
                  shading: {
                    fill: 'F2F2F2'
                  },
                  children: [
                    new Paragraph({
                      alignment: AlignmentType.CENTER,
                      children: [
                        new TextRun({
                          text: `${Math.round(soundRPM?.hz250.unitReturn ?? 0)}`,
                          font: 'calibri',
                          size: 20,
                        })
                      ]
                    })
                  ],
                }),
                new TableCell({
                  borders: default_table_border,
                  shading: {
                    fill: 'F2F2F2'
                  },
                  children: [
                    new Paragraph({
                      alignment: AlignmentType.CENTER,
                      children: [
                        new TextRun({
                          text: `${Math.round(soundRPM?.hz500.unitReturn ?? 0)}`,
                          font: 'calibri',
                          size: 20,
                        })
                      ]
                    })
                  ],
                }),
                new TableCell({
                  borders: default_table_border,
                  shading: {
                    fill: 'F2F2F2'
                  },
                  children: [
                    new Paragraph({
                      alignment: AlignmentType.CENTER,
                      children: [
                        new TextRun({
                          text: `${Math.round(soundRPM?.hz1000.unitReturn ?? 0)}`,
                          font: 'calibri',
                          size: 20,
                        })
                      ]
                    })
                  ],
                }),
                new TableCell({
                  borders: default_table_border,
                  shading: {
                    fill: 'F2F2F2'
                  },
                  children: [
                    new Paragraph({
                      alignment: AlignmentType.CENTER,
                      children: [
                        new TextRun({
                          text: `${Math.round(soundRPM?.hz2000.unitReturn ?? 0)}`,
                          font: 'calibri',
                          size: 20,
                        })
                      ]
                    })
                  ],
                }),
                new TableCell({
                  borders: default_table_border,
                  shading: {
                    fill: 'F2F2F2'
                  },
                  children: [
                    new Paragraph({
                      alignment: AlignmentType.CENTER,
                      children: [
                        new TextRun({
                          text: `${Math.round(soundRPM?.hz4000.unitReturn ?? 0)}`,
                          font: 'calibri',
                          size: 20,
                        })
                      ]
                    })
                  ],
                }),
                new TableCell({
                  borders: default_table_border,
                  shading: {
                    fill: 'F2F2F2'
                  },
                  children: [
                    new Paragraph({
                      alignment: AlignmentType.CENTER,
                      children: [
                        new TextRun({
                          text: `${Math.round(soundRPM?.hz8000.unitReturn ?? 0)}`,
                          font: 'calibri',
                          size: 20,
                        })
                      ]
                    })
                  ],
                }),
              ],
            }),
          ],
        }),
      ],
    },
  ];
};

// // TODO: Style this stuff
const generateQuoteDocx = async ({
  jobName,
  quote,
  products,
  fanFitData,
  sizings
}: {
  jobName: string;
  quote: Quote;
  products: Product[];
  fanFitData: Fan_Fit[] | undefined;
  sizings: Control_Panel_Sizing[] | undefined;
}) => {

  let numBlank = 0;
  products.forEach((p) => {
    if(p.configuration === null) numBlank++;
  });

  if(numBlank > 0){
    toast.error("Unable to create submittal. Not all products have a config set.");
    return ;
  }

  const productElements = products.map((p) => generateProductSubmittalElements({
    fanFitData,
    sizings,
    product: p,
  }))

  const doc = new Document({
    sections: _.flatten(productElements),
  });

  Packer.toBlob(doc).then((blob) => {
    saveAs(blob, `${jobName} - ${quote.name} - MAS EC Data.docx`);
  });
};

export default generateQuoteDocx;