import { Control_Panel_Sizing, Product } from "../generated/graphql";
import { calculateAltitudeCorrectionFactor, calculateEfficiency, calculateFanArrayWeight, calculateFLA, calculateInterpolatedFla, calculateMCANew, calculateMOCPNew, calculateOperatingInputHorserpower, calculateOperatingRPM, calculateRedundancy, calculateRPMSoundPower } from '../helpers/generate-configuration-query';
import { getPanelAll } from '../helpers/calculate-custom-controls';
import { round } from "lodash";
import { findDesignCoefficients, findMaxCFM } from "./fan-curve";
import { RPMCoefficient } from "./file-checker";

// Email helpers

const azureRoot = process.env.REACT_APP_AZURE_FUNCTIONS_URL || "";

const toList = "leo.orders@mas-hvac.com;x+1208404097774444@mail.asana.com";

const sendTransmitEmails = ({jobNumber, jobName, quoteName, jobId, username, modelNumbers, modelAmounts, controlsType}) =>{
    if (typeof(process.env.REACT_APP_ENV) !== "undefined" && !!process.env.REACT_APP_ENV && process.env.REACT_APP_ENV === "development"){
        console.log("No emails sent, just testing");
        return "testing";
    } 
    return fetch(`${azureRoot}/api/SendAlertEmail`, {
        method: "POST",
        body: JSON.stringify({
            mailerName: "Leo Orders - Automated Message",
            from: "leo.orders@mas-hvac.com",
            secret: "noIdeaIfThisWillWorkLol",
            toList,
            jobNumber,
            jobName,
            quoteName,
            jobId,
            username,
            modelNumbers,
            modelAmounts,
            controlsType,
            function: "transmit",
        }),
    }).then((response) => {
        if (!response.ok) console.error("Error:",response);
        else console.log("Successfully sent emails");
    }).catch((e) => {
        console.log("Error sending email");
    });
}

const sendUnTransmitEmails = ({jobNumber, jobName, quoteName, jobId}) =>{
    if (typeof(process.env.REACT_APP_ENV) !== "undefined" && !!process.env.REACT_APP_ENV && process.env.REACT_APP_ENV === "development"){
        console.log("No emails sent, just testing");
        return "testing";
    } 
    return fetch(`${azureRoot}/api/SendAlertEmail`, {
        method: "POST",
        body: JSON.stringify({
            mailerName: "Leo Orders - Automated Message",
            from: "leo.orders@mas-hvac.com",
            secret: "noIdeaIfThisWillWorkLol",
            toList,
            jobNumber,
            jobName,
            quoteName,
            jobId,
            function: "untransmit",
        })
    }).then((response) => {
        if (!response.ok) console.error("Error:",response);
        else console.log("Successfully sent emails");
    }).catch((e) => {
        console.log("Error sending email");
    });
}

const sendFailureEmail = ({jobName, quoteName, errorType}) => {
    if (typeof(process.env.REACT_APP_ENV) !== "undefined" && !!process.env.REACT_APP_ENV && process.env.REACT_APP_ENV === "development"){
        console.log("No emails sent, just testing");
        return "testing";
    }
    
    return fetch(`${azureRoot}/api/SendAlertEmail`, {
        method: "POST",
        body: JSON.stringify({
            mailerName: "Leo Orders - Automated Message",
            from: "leo.orders@mas-hvac.com",
            secret: "noIdeaIfThisWillWorkLol",
            toList: "aevelhoch@mas-hvac.com",
            jobName,
            quoteName,
            function: errorType,
        })
    }).then((response) => {
        if (!response.ok) console.error("Error:",response);
        else console.log("Sent error report to developer");
    }).catch((e) => {
        console.log("Error sending error report to developer");
    })
}

// Other helpers

const soundPrettyOutput = (value: any) => {
    if (!value || value < 0)
        return "-";
    return value
}

const calculateDRCCodeString = (product: Product) => {
    // First, "MAS"
    let drcCode = "MAS";
    // Voltage (from config/overridden), Phase (Always 3), Frequency (Always 60)
    drcCode += (product.voltage_override !== null) ? product.voltage_override.toString().padStart(3, '0') : product.configuration.voltage.toString().padStart(3, '0');
    drcCode += "3";
    drcCode += "60";
    // Enclosure rating (N4 outside, N1 indoors)
    if (product.outdoor_rating === true) drcCode += "N4";
    else drcCode += "N1";
    // Disconnect type (FU for fused)
    drcCode += "FU";
    // Fan quantity
    drcCode += product.configured_fan_count.toString().padStart(2, '0');
    // Motor amp rating (Must be 4 characters, including periods)
    let fla = product.configuration.fla_460;
    if (product.voltage_override !== null){
        fla = calculateInterpolatedFla(product.configuration.min_v_fla, product.configuration.max_v_fla, product.voltage_override ?? product.configuration.voltage);
    }
    if (fla.toString().length < 4) drcCode += fla.toString().padStart(4,'0');
    else if (fla.toString().length === 4) drcCode += fla.toString();
    else {
        let splitFla = fla.toString().split(".");
        if (splitFla.length < 2) drcCode += "ERR";
        else {
            let wholeChars = splitFla[0].length;
            if (wholeChars === 1) drcCode += fla.toFixed(2);
            if (wholeChars === 2) drcCode += fla.toFixed(1);
            if (wholeChars === 3) drcCode += splitFla.padStart(4, '0');
            if (wholeChars === 4) drcCode += splitFla[0];
        }
    }
    // Y
    drcCode += "Y";
    // HOA switch
    drcCode += "S";
    // N
    drcCode += "N";
    // Manufacturer
    if (product.configuration.manufacturer === "Rosenberg") drcCode += "RBG";
    else if (product.configuration.manufacturer === "Ziehl-Abegg") drcCode += "ZAG";
    else drcCode += "___";
    // Placeholder? 6 zeros
    drcCode += "000000";
    // Quick ship (might be updated later)
    drcCode += "NN";
    // Special job (might be updated later)
    drcCode += "ZY";
    // Placeholder? 14 Zs
    drcCode += "ZZZZZZZZZZZZZZ";

    return drcCode;
}

const magicNumberSerialStarting = 1175642;

const modelToNumberMap = {
    "N88-40327" : "01",
    "N88-45301" : "02",
    "N88-45316" : "03",
    "N88-50322" : "04",
    "N88-50701" : "05",
    "N88-56604" : "06",
    "N88-56605" : "07",
    "N88-56700" : "08",
    "N88-63314" : "09",
    "116553/A01" : "50",
    "116169/A01" : "51",
    "116175/A01" : "52",
    "117631/A01" : "53",
    "117050/A01" : "54",
    "116181/A01" : "55",
    "116165/A01" : "56",
    "116177/A01" : "57",
    null : "__",
};


const calculateModelNumber = (product: Product) => {
    if (product?.configuration === undefined || product?.configuration === null) return "INVALID_CONFIG";
    let modelNumber = "";

    // model number is made up of 11 components
    // 1  - Controls type
    if (product?.mas_ec_plus === true) modelNumber += "C";
    else if (product?.mas_ec_plus === false) modelNumber += "B";
    else modelNumber += "_";
    // 2  - Air flow orientation (chosen after lock)
    if (product?.air_flow_orientation !== null) modelNumber += product?.air_flow_orientation;
    else modelNumber += "_";
    // 3  - Dampers
    if (product?.backdraft_dampers) modelNumber += "1";
    else modelNumber += "0";
    
    modelNumber += "-";

    // 4  - Fan brand
    if (product?.configuration.manufacturer === "Rosenberg") modelNumber += "R";
    else if (product?.configuration.manufacturer === "Ziehl-Abegg") modelNumber += "Z";
    else modelNumber += "_";
    // 5  - Fan size/model
    if (modelToNumberMap.hasOwnProperty(product?.configuration.model)) modelNumber += modelToNumberMap[product?.configuration.model];
    else modelNumber += "__";

    modelNumber += "-";

    // 6  - Fan quantity
    if (!Number.isInteger(product.configured_fan_count)) modelNumber += "XX";
    else modelNumber += `${product.configured_fan_count}`.padStart(2, '0');
    // 7  - MSP (A: none, B: has; Only 'has' for EC+ with more than 1 fan)
    if (product?.mas_ec_plus) modelNumber += "B"
    else modelNumber += "A";

    modelNumber += "-";

    // 8  - Voltage
    console.log("product.set_voltage",product.set_voltage);
    console.log("product.power",product.power);
    if (product?.voltage_override !== null){
        modelNumber += product?.voltage_override;
    } else if (product?.set_voltage !== null){
        if (product?.set_voltage === "460") modelNumber += "460";
        else if (product?.set_voltage === "230") modelNumber += "230";
        else if (product?.set_voltage === "208") modelNumber += "208";
        else modelNumber += "___";
    } else {
        if (product.power === 460) modelNumber += "460";
        else if (product.power === 208) modelNumber += "208";
        else modelNumber += "___";
    }

    modelNumber += "-";

    // 9  - Panel rating
    if (product?.outdoor_rating === true) modelNumber += "4";
    else modelNumber += "1";
    // 10 - BACnet (chosen after lock)
    if (product?.BACnet === null) modelNumber += "0";
    else if (product?.BACnet === "IP") modelNumber += "1";
    else if (product?.BACnet === "MSTP") modelNumber += "2";
    // 11 - HMI (chosen after lock)
    if (product?.local_HMI) modelNumber += "1";
    else modelNumber += "0";

    return modelNumber;
}

/**
 * Convert a 2D array into a CSV string
 */
function arrayToCsv(data: any[][]){
    return data.map(row =>
      row
      .map(String)  // convert every value to String
      .map((v: String) => v.replaceAll('"', '""'))  // escape double quotes
      .map((v: String) => `"${v}"`)  // quote it
      .join(',')  // comma-separated
    ).join('\r\n');  // rows starting on new lines
}

/**
 * Download contents as a file
 * Source: https://stackoverflow.com/questions/14964035/how-to-export-javascript-array-info-to-csv-on-client-side
 */
function downloadBlob(content: any, filename: string, contentType: any) {
    // Create a blob
    var blob = new Blob([content], { type: contentType });
    var url = URL.createObjectURL(blob);
  
    // Create a link to download it
    var pom = document.createElement('a');
    pom.href = url;
    pom.setAttribute('download', filename);
    pom.click();
    pom.remove();
}

const generateOrderingCSV = (products : Product[], jobName: string, quoteName: string, sizings: Control_Panel_Sizing[]) => {
    let productCsvAs2dArray = [["Leo Tag", "Unit Tag", "Mas Model Number", "MAS Serial Number", "Control Type", "Location", "Voltage", "Fan Quantity",
        "Fan FLA", "Fan HP", "MCA", "MOCP", "Panel Depth", "Panel Width", "Panel Height", "Fan Brand", "BACnet Protocol", "Local HMI",
        "Leo DRC Price", "DRC Code String",
        "","","MSP Enclosure Type","MSP1 First Fan#","MSP1 J-Box FanQty","MSP1 #PowerLeads","MSP2 First Fan#","MSP2 J-Box FanQty","MSP2 #PowerLeads","MSP3 First Fan#","MSP3 J-Box FanQty","MSP3 #PowerLeads"]];
    products.forEach(product => {
        let fla = calculateFLA(product.configuration.fla_460, product.voltage_override, product.configuration.min_v_fla, product.configuration.max_v_fla);
        product.has_serial_number.forEach(serial=> {
            // new 
            let newRow = [];
            // - Leo Tag
            newRow.push(product.tag);
            // - Unit Tag
            newRow.push(!!serial.unit_tag ? serial.unit_tag : ""); 
            // - MAS model number 
            newRow.push(product.model_number);
            // - MAS serial number
            newRow.push(serial.serial_number);
            // EC basic or EC+
            newRow.push(product.mas_ec_plus ? "EC+" : "EC basic");
            // - Location (Indoor or Outdoor)
            if (product.outdoor_rating === true || !product.mas_ec_plus) newRow.push("Outdoor");
            else newRow.push("Indoor");
            // - Voltage
            newRow.push(product.voltage_override ?? product.set_voltage) 
            // - Fan Quantity
            newRow.push(product.configured_fan_count);
            // - Fan FLA
            // newRow.push(product.configuration.fla_460 ?? product.configuration.fla_400) 
            newRow.push(fla) 
            // - Fan HP (This is the nominal HP, not the operating HP)
            newRow.push(product.configuration.nominal_hp) 
            // - MCA
            newRow.push(round(calculateMCANew({fla: fla, fanCount: product.configured_fan_count, voltage: product.voltage_override ?? product.set_voltage ?? product.configuration.voltage, mas_ec_plus: product.mas_ec_plus}), 1));
            // - MOCP
            newRow.push(calculateMOCPNew({fla: fla, fanCount: product.configured_fan_count, voltage: product.voltage_override ?? product.set_voltage ?? product.configuration.voltage, mas_ec_plus: product.mas_ec_plus}));
            // - Panel Size (Length, Width, Height)
            const productPanel = getPanelAll(product.configured_fan_count, calculateMOCPNew({fla: fla, fanCount: product.configured_fan_count, voltage: product.voltage_override ?? product.set_voltage, mas_ec_plus: product.mas_ec_plus}), sizings, product.mas_ec_plus);
            newRow.push(productPanel.sizing.depth);
            newRow.push(productPanel.sizing.width);
            newRow.push(productPanel.sizing.height);
            // - Fan Brand
            newRow.push(product.configuration.manufacturer);
            // - BACnet Protocol
            if (product.BACnet === null) newRow.push("None");
            else newRow.push(product.BACnet)
            // - Local HMI
            if (product.local_HMI === true) newRow.push(`10"`);
            else newRow.push("None");
            // - DRC Price (Leo)
            if(product.mas_ec_plus) newRow.push(productPanel.cost - 1750);
            else newRow.push(productPanel.cost);
            // -- DRC Code String
            newRow.push(calculateDRCCodeString(product));
            // Two blank columns 
            newRow.push("");
            newRow.push("");
            // - MSP Enclosure Type 
            newRow.push("Type1");
            // - MSP1 First Fan# 
            newRow.push("1");
            // - MSP1 J-Box FanQty 
            if (product.configured_fan_count === 1) newRow.push("")
            else if (product.configured_fan_count >= 10) newRow.push("10");
            else newRow.push(product.configured_fan_count);
            // - MSP1 #PowerLeads
            if (product.configured_fan_count === 1) newRow.push("");
            else if (product.configured_fan_count > 1 && product.configured_fan_count <= 5) newRow.push("1");
            else if (product.configured_fan_count > 8) newRow.push("2");
            else {
                if(fla * product.configured_fan_count < 60) newRow.push("1");
                else newRow.push("2");
            }
            // - MSP2 First Fan# 
            if (product.configured_fan_count < 11) newRow.push("");
            else newRow.push("11");
            // - MSP2 J-Box FanQty 
            if (product.configured_fan_count < 11) newRow.push("");
            else if (product.configured_fan_count > 20) newRow.push("10");
            else newRow.push(product.configured_fan_count - 10);
            // - MSP2 #PowerLeads
            if (product.configured_fan_count < 11) newRow.push("");
            else if (product.configured_fan_count >= 11 && product.configured_fan_count < 19) newRow.push("1");
            else newRow.push("2");
            // - MSP3 First Fan# 
            if (product.configured_fan_count < 21) newRow.push("");
            else newRow.push("21");
            // - MSP3 J-Box FanQty
            if (product.configured_fan_count < 21) newRow.push("");
            else newRow.push(product.configured_fan_count - 20);
            // - MSP3 #PowerLeads
            if (product.configured_fan_count < 21) newRow.push("");
            else newRow.push("1");
            // Add row to csv
            productCsvAs2dArray.push(newRow);
        });
    });
    let csv = arrayToCsv(productCsvAs2dArray);
    const fileName = `[${jobName} - ${quoteName}] DRC Ordering.csv`;
    downloadBlob(csv, fileName, 'text/csv;charset=utf-8;');
}

const generateScheduleInfoCSV = (products : Product[], jobName: string, quoteName: string, quoteSoftware: string, quoteFirmware: string)  => {
    let productCsvAs2dArray = [["Leo Tag", "Unit Tag", "MAS Model Number", "MAS Serial Number", "Fan Brand", "Fan Model Number", "Fan Quantity", "Voltage",
        "FLA", "Fan HP", "MCA", "MOCP", "k Factor", "AFMS dP Max", "Total CFM", "CFM-PID-P", "CFM-PID-I", "Firmware", "Software", "Communication Protocol"]];
    products.forEach(product => {
        let fla = calculateFLA(product.configuration.fla_460, product.voltage_override, product.configuration.min_v_fla, product.configuration.max_v_fla);
        product.has_serial_number.forEach(serial=> {
            let newRow = [];
            // - Leo Tag
            newRow.push(product.tag) 
            // - Unit Tag
            newRow.push(!!serial.unit_tag ? serial.unit_tag : ""); 
            // - MAS model number 
            newRow.push(product.model_number);
            // - MAS serial number 
            newRow.push(serial.serial_number);
            // - Fan Brand
            newRow.push(product.configuration.manufacturer) 
            // - Fan Model Number
            newRow.push(product.configuration.model) 
            // - Fan Quantity
            newRow.push(product.configured_fan_count) 
            // - Voltage
            newRow.push(product.voltage_override ?? product.set_voltage) 
            // - FLA
            newRow.push(fla) 
            // - Fan HP (This is the nominal HP, not the operating HP)
            newRow.push(product.configuration.nominal_hp) 
            // - MCA
            newRow.push(round(calculateMCANew({fla: fla, fanCount: product.configured_fan_count, voltage: product.voltage_override ?? product.set_voltage ?? product.configuration.voltage, mas_ec_plus: product.mas_ec_plus}), 1));
            // - MOCP
            newRow.push(calculateMOCPNew({fla: fla, fanCount: product.configured_fan_count, voltage: product.voltage_override ?? product.set_voltage ?? product.configuration.voltage, mas_ec_plus: product.mas_ec_plus}));
            // - k Factor
            newRow.push(product.configuration.k_factor) 
            // - AFMS dP Max
            newRow.push(((product.air_flow / product.configured_fan_count) / product.configuration.k_factor) ** 2 > 4.5 ? "0 - 10" : "0 - 5") 
            // - Total CFM
            newRow.push(product.air_flow) 
            // - CFM-PID-P (Total CFM * 2)
            newRow.push((product.air_flow * 2)) 
            // - CFM-PID-I (Always listed as “20”)
            newRow.push(20) 
            // - Firmware
            if (product.mas_ec_plus) newRow.push(quoteFirmware);
            else newRow.push("");
            // - Software
            if (product.mas_ec_plus) newRow.push(quoteSoftware);
            else newRow.push("");
            // - Communication Protocol
            if (product.BACnet === null) newRow.push("None");
            else newRow.push(product.BACnet)
            // Add row to csv
            productCsvAs2dArray.push(newRow);
        });
    });
    const csv = arrayToCsv(productCsvAs2dArray);
    const fileName = `[${jobName} - ${quoteName}] Engineering Master.csv`;
    downloadBlob(csv, fileName, 'text/csv;charset=utf-8;');
}

const generateRawInfoCSV = ({products, jobName, quoteName, sizings})  => {
    let productCsvAs2dArray = [["Product Tag","Fan Quantity","Input CFM","TSP","Input HP Each","RPM","Efficiency","Redundancy","Array Weight","Voltage",
        "FLA","MCA","MOCP","Fan Manufacturer","Fan Model","Blade Material","HP each","Max RPM","Weight","Controls Type","Indoor or Outdoor",
        "63 Hz Radiated","125 Hz Radiated","250 Hz Radiated","500 Hz Radiated","1000 Hz Radiated","2000 Hz Radiated","4000 Hz Radiated","8000 Hz Radiated",
        "63 Hz Unit Discharge","125 Hz Unit Discharge","250 Hz Unit Discharge","500 Hz Unit Discharge","1000 Hz Unit Discharge","2000 Hz Unit Discharge","4000 Hz Unit Discharge","8000 Hz Unit Discharge",
        "63 Hz Unit Return","125 Hz Unit Return","250 Hz Unit Return","500 Hz Unit Return","1000 Hz Unit Return","2000 Hz Unit Return","4000 Hz Unit Return","8000 Hz Unit Return",
    ]];

    products.forEach((product: Product) => {
        const cfm = product?.air_flow ?? 0;
        const tsp = product?.total_static_pressure ?? 0;
        const altitudeCorrectionFactor = calculateAltitudeCorrectionFactor(product?.altitude);
        const backdraftDampersCorrectionValue = product?.backdraft_dampers ? 0.2 : 0;
        const adjustedTSP = (product?.total_static_pressure ?? 0) * altitudeCorrectionFactor + backdraftDampersCorrectionValue;
        const fanCount = product?.configured_fan_count

        // Calculate coefficients
        const rawCoefficients = !!product.configuration?.rmp_coefficients
            ? JSON.parse(product.configuration?.rmp_coefficients) : {};

        let coefficients: RPMCoefficient[] = rawCoefficients? Object.values(rawCoefficients): [];

        let designCoefficients = findDesignCoefficients({
            cfm,
            tsp: adjustedTSP,
            coefficients,
            fanCount,
            model: null
        });

        const horsepower = designCoefficients.upper.coefficient?.powerHp && designCoefficients.lower.coefficient?.powerHp ? calculateOperatingInputHorserpower(
            product.air_flow,
            designCoefficients.fanCount || 0,
            designCoefficients.lower.coefficient?.powerHp,
            designCoefficients.upper.coefficient?.powerHp,
            designCoefficients.interpolation
        ) : 0;

        const operatingSpeed = designCoefficients.upper ? calculateOperatingRPM({
            RPMUpperBound: designCoefficients.upper.rpm,
            RPMLowerBound: designCoefficients.lower.rpm,
            Interpolation: designCoefficients.interpolation
        }) : 0;

        const efficiency = calculateEfficiency({
            tsp: adjustedTSP,
            cfm,
            fanCount,
            horsepower,
        });

        const maxRPM = Math.max(...(Object.keys(rawCoefficients).map((k) => parseInt(k))));
        const maxRPMCoefficients = coefficients.sort((a, b) => b.RPM - a.RPM)[0];
        const maxCFM = maxRPMCoefficients
            ? findMaxCFM({
            tsp: adjustedTSP,
            coefficient: maxRPMCoefficients.pressure,
        }) : 0;

        const redundancy = calculateRedundancy({
            maxCFM,
            fanCount,
            designCFM: cfm,
        });

        let fla = calculateFLA(product.configuration.fla_460, product.voltage_override, product.configuration.min_v_fla, product.configuration.max_v_fla);
        const mca = calculateMCANew({ fla: fla, fanCount, voltage: product.voltage_override ?? product.configuration?.voltage ?? 0, mas_ec_plus: product.mas_ec_plus });
        const mocp = calculateMOCPNew({ fla: fla, fanCount, voltage: product.voltage_override ?? product.configuration?.voltage ?? 0, mas_ec_plus: product.mas_ec_plus });

        const calculatedSizing = getPanelAll(fanCount, mocp, sizings, product.mas_ec_plus, product.outdoor_rating);
        const systemWeight = calculateFanArrayWeight({
            fanCount: fanCount,
            fanWeight: !!product.configuration?.fan_weight ? product.configuration.fan_weight : 0 ,
            panelWeight: calculatedSizing.sizing.weight,
            bulkheadHeight: product.height || 0,
            bulkheadWidth: product.width || 0,
        });

        const soundRPM = !!designCoefficients.upper.coefficient && !!designCoefficients.lower.coefficient
            ? calculateRPMSoundPower(fanCount, product.air_flow / fanCount, designCoefficients.lower.coefficient, designCoefficients.upper.coefficient, designCoefficients.interpolation)
            : null;

        let newRow = [];
        // Product Tag
        newRow.push(product.tag);
        // Fan quantity
        newRow.push(fanCount);
        // Input cfm
        newRow.push(cfm);
        // tsp
        newRow.push(tsp);
        // input hp each
        newRow.push(horsepower);
        // rpm
        newRow.push(operatingSpeed);
        // efficiency
        newRow.push(efficiency);
        // redundancy
        newRow.push(redundancy);
        // array weight
        newRow.push(systemWeight);
        // voltage
        newRow.push(product.voltage_override ?? product.configuration.voltage ?? 0);
        // fla
        newRow.push(fla);
        // mca
        newRow.push(mca);
        // mocp
        newRow.push(mocp);
        // fan manufacturer
        newRow.push(product.configuration.manufacturer);
        // fan model
        newRow.push(product.configuration.model);
        // blade material
        newRow.push(product.configuration.blade_material);
        // hp each
        newRow.push(product.configuration.nominal_hp);
        // max rpm
        newRow.push(maxRPM);
        // weight
        newRow.push(product.configuration.fan_weight);
        // controls type
        newRow.push(product.mas_ec_plus ? "EC+" : "Basic Controls");
        // indoor or outdoor
        newRow.push(product.outdoor_rating ? "Outdoor" : "Indoor");
        // sound power (all types and frequencies)
        // Radiated
        // 63 Hz
        newRow.push(product.configuration.model === "N88-63314" ? "-" : soundPrettyOutput(soundRPM?.hz63.radiated.toFixed(0)));
        // 125 Hz
        newRow.push(soundPrettyOutput(soundRPM?.hz125.radiated.toFixed(0)));
        // 250 Hz
        newRow.push(soundPrettyOutput(soundRPM?.hz250.radiated.toFixed(0)));
        // 500 Hz
        newRow.push(soundPrettyOutput(soundRPM?.hz500.radiated.toFixed(0)));
        // 1k Hz
        newRow.push(soundPrettyOutput(soundRPM?.hz1000.radiated.toFixed(0)));
        // 2k Hz
        newRow.push(soundPrettyOutput(soundRPM?.hz2000.radiated.toFixed(0)));
        // 4k Hz
        newRow.push(soundPrettyOutput(soundRPM?.hz4000.radiated.toFixed(0)));
        // 8k Hz
        newRow.push(soundPrettyOutput(soundRPM?.hz8000.radiated.toFixed(0)));
        // Unit Discharge
        // 63 Hz
        newRow.push(product.configuration.model === "N88-63314" ? "-" : soundPrettyOutput(soundRPM?.hz63.unitDischarge.toFixed(0)));
        // 125 Hz
        newRow.push(soundPrettyOutput(soundRPM?.hz125.unitDischarge.toFixed(0)));
        // 250 Hz
        newRow.push(soundPrettyOutput(soundRPM?.hz250.unitDischarge.toFixed(0)));
        // 500 Hz
        newRow.push(soundPrettyOutput(soundRPM?.hz500.unitDischarge.toFixed(0)));
        // 1k Hz
        newRow.push(soundPrettyOutput(soundRPM?.hz1000.unitDischarge.toFixed(0)));
        // 2k Hz
        newRow.push(soundPrettyOutput(soundRPM?.hz2000.unitDischarge.toFixed(0)));
        // 4k Hz
        newRow.push(soundPrettyOutput(soundRPM?.hz4000.unitDischarge.toFixed(0)));
        // 8k Hz
        newRow.push(soundPrettyOutput(soundRPM?.hz8000.unitDischarge.toFixed(0)));
        // Unit Return
        // 63 Hz
        newRow.push(product.configuration.model === "N88-63314" ? "-" : soundPrettyOutput(soundRPM?.hz63.unitReturn.toFixed(0)));
        // 125 Hz
        newRow.push(soundPrettyOutput(soundRPM?.hz125.unitReturn.toFixed(0)));
        // 250 Hz
        newRow.push(soundPrettyOutput(soundRPM?.hz250.unitReturn.toFixed(0)));
        // 500 Hz
        newRow.push(soundPrettyOutput(soundRPM?.hz500.unitReturn.toFixed(0)));
        // 1k Hz
        newRow.push(soundPrettyOutput(soundRPM?.hz1000.unitReturn.toFixed(0)));
        // 2k Hz
        newRow.push(soundPrettyOutput(soundRPM?.hz2000.unitReturn.toFixed(0)));
        // 4k Hz
        newRow.push(soundPrettyOutput(soundRPM?.hz4000.unitReturn.toFixed(0)));
        // 8k Hz
        newRow.push(soundPrettyOutput(soundRPM?.hz8000.unitReturn.toFixed(0)));
        
        // Add row to csv
        productCsvAs2dArray.push(newRow);

        // Generate a fan curve image and add to array for us to zip up later

    });
    const csv = arrayToCsv(productCsvAs2dArray);
    const fileName = `[${jobName} - ${quoteName}] Raw Data.csv`;
    downloadBlob(csv, fileName, 'text/csv;charset=utf-8;');
}

const controlsTeamExportHelpers = {
    sendTransmitEmails,
    sendUnTransmitEmails,
    calculateModelNumber,
    magicNumberSerialStarting,
    generateOrderingCSV,
    generateScheduleInfoCSV,
    generateRawInfoCSV,
    sendFailureEmail
};

export default controlsTeamExportHelpers;