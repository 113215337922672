import { jsPDF } from "jspdf";
import autoTable from 'jspdf-autotable'

import { Control_Panel_Sizing, Cost, Fan_Fit, Product, Quote } from "../generated/graphql";

import { formatDate } from "./date-utils";
import parseMoney, { formatMoney } from "./money-utils";

import { drawFanCurveChart } from "./chart";
import { RPMCoefficient } from "./file-checker";
import { findDesignCoefficients, findDesignPoint, findMaxCFM, generateRPMCurve, generateSystemCurve } from "./fan-curve";
import { calculateEfficiency, calculateFLA, calculateFanArrayWeight, calculateMCANew, calculateMOCPNew, calculateOperatingInputHorserpower, calculateOperatingRPM, calculateRPMSoundPower, calculateRedundancy, getCostBreakdown } from "./generate-configuration-query";

import masLogo from '../assets/mas-logo.png';
import { quoteSumTotal } from "./quote";
import { getPanelCost } from "./calculate-custom-controls";

export const generateQuotePDF = ({
  jobName,
  user,
  quote,
  costs,
  sizings,
}: {
  jobName: string,
  user: { given_name?: string, family_name?: string, email?: string } | undefined,
  quote: Quote,
  costs: Cost[] | undefined,
  sizings: Control_Panel_Sizing[] | undefined,
}) => {
  const doc = new jsPDF();

  doc.addImage(masLogo, "PNG", 125, 0, 80, 40);

  doc.setFontSize(14);
  doc.text(`Project: ${jobName}`, 10, 19);
  doc.setFontSize(10);
  doc.text(`Date: ${formatDate(new Date().toUTCString())}`, 10, 29);

  doc.setFont('helvetica', 'bold');
  doc.text(quote.name, 10, 50);

  let anyDiscountedPrice = false;

  const products = quote.products.map((p, i) => {
    const quantity = p?.configuration_quantity ?? 1;
    const configuration = p.configuration!;

    // Calculate controls cost 
    const fla = calculateFLA(p.configuration.fla_460, p.voltage_override, p.configuration.min_v_fla, p.configuration.max_v_fla);
    const productMOCP = calculateMOCPNew({
      fla: fla,
      fanCount: p.configured_fan_count ?? 1,
      voltage: p.voltage_override ?? p.configuration.voltage,
      mas_ec_plus: p.mas_ec_plus,
    });
    const controlsCost = getPanelCost(p.configured_fan_count, productMOCP, sizings, p.mas_ec_plus, p.outdoor_rating);

    if (p.large_scale_pricing) anyDiscountedPrice = true;

    const costBreakdown = getCostBreakdown({
      fanCost: parseMoney(p.configuration?.price) ?? 0,
      fanCount: p.configured_fan_count ?? 1,
      // controlPanelCost: sizing?.cost ?? 0, // TODO: fix this as well
      controlPanelCost: controlsCost,
      bulkheadHeight: p.height ?? 0,
      bulkheadWidth: p.width ?? 0,
      costPerSQFT:
        parseMoney(
          (costs ?? []).find((c) => c.name === "Bulkhead_Cost_per_SQFT")?.value
        ) ?? 0,
      miscFlatCost:
        parseMoney((costs ?? []).find((c) => c.name === "Misc_Flat")?.value) ?? 0,
      miscVariableCost:
        parseMoney((costs ?? []).find((c) => c.name === "Misc_Variable:")?.value) ??
        0,
      damperCost:
        parseMoney((costs ?? []).find((c) => c.name === "Backdraft_Damper")?.value) ??
        0,
      hasDampers: p.backdraft_dampers ?? false,
      hasTransducers: p.pressure_transducers ?? false,
      largeScalePricing: p.large_scale_pricing,
      discountedFanCost: p.discounted_fan_price,
    });

    return ({
      index: i + 1,
      description: `${p.tag}: ${p.configured_fan_count} Fans, ${configuration?.model}, ${Math.round(p.air_flow ?? 0)} CFM,\n ${p.total_static_pressure} inwc, ${p.height}" H x ${p.width}" W, ${p.mas_ec_plus ? "MAS EC+ (BACnet)" : "MAS EC"}`,
      quantity,
      price: p.configuration?.price,
      unitPrice: formatMoney(costBreakdown.totalCost),
      total: formatMoney(quantity * costBreakdown.totalCost),
      originalPrice: p.large_scale_pricing ? formatMoney(costBreakdown.undiscountedTotalCost) : "",
    });
  });

  const totalPrice = quoteSumTotal(
    quote.products,
    sizings,
    costs
  );

  if (!anyDiscountedPrice){
      autoTable(doc, {
        startY: 53,
        margin: { top: 5, left: 10 },
        theme: 'plain',
        showHead: 'firstPage',
        showFoot: 'lastPage',
        headStyles: {
          fillColor: '#2D90FF',
          textColor: '#fff',
        },
        bodyStyles: {
          fillColor: '#fff',
          textColor: '#000',
          lineColor: '#2D90FF',
          lineWidth: {
            bottom: 0.2
          }
        },
        footStyles: {
          fillColor: '#fff',
          textColor: '#000',
          lineColor: '#2D90FF',
        },
        columnStyles: {
          0: {
            lineColor: '#2D90FF',
            lineWidth: {
              left: 0.2,
              bottom: 0.2
            }
          },
          4: {
            lineColor: '#2D90FF',
            lineWidth: {
              right: 0.2,
              bottom: 0.2
            }
          },
        },
        columns: [
          { header: 'Item', dataKey: 'index' },
          { header: 'Description', dataKey: 'description' },
          { header: 'Quantity', dataKey: 'quantity' },
          { header: 'Array Price', dataKey: 'unitPrice' },
          { header: 'Price Total', dataKey: 'total' },
        ],
        body: products,
        foot: [
          [
            {
              content: ' ',
              styles: {
                lineColor: '#2D90FF',
                lineWidth: {
                  left: 0.2,
                  bottom: 0.2
                }
              }
            },
            {
              content: ' ',
              styles: {
                lineColor: '#2D90FF',
                lineWidth: {
                  bottom: 0.2
                }
              }
            },
            {
              content: ' ',
              styles: {
                lineColor: '#2D90FF',
                lineWidth: {
                  bottom: 0.2
                }
              }
            },
            {
              content: 'Total',
              styles: {
                lineColor: '#2D90FF',
                lineWidth: {
                  bottom: 0.2
                }
              }
            },
            {
              content: totalPrice,
              styles: {
                lineColor: '#2D90FF',
                lineWidth: {
                  right: 0.2,
                  bottom: 0.2
                }
              }
            },
          ]
        ],
      });
  } else {
    autoTable(doc, {
      startY: 53,
      margin: { top: 5, left: 10 },
      theme: 'plain',
      showHead: 'firstPage',
      showFoot: 'lastPage',
      headStyles: {
        fillColor: '#2D90FF',
        textColor: '#fff',
      },
      bodyStyles: {
        fillColor: '#fff',
        textColor: '#000',
        lineColor: '#2D90FF',
        lineWidth: {
          bottom: 0.2
        }
      },
      footStyles: {
        fillColor: '#fff',
        textColor: '#000',
        lineColor: '#2D90FF',
      },
      columnStyles: {
        0: {
          lineColor: '#2D90FF',
          lineWidth: {
            left: 0.2,
            bottom: 0.2
          }
        },
        3: {
          textColor: "#FF0000",
          lineColor: '#2D90FF',
          lineWidth: {
            bottom: 0.2
          }
        },
        5: {
          lineColor: '#2D90FF',
          lineWidth: {
            right: 0.2,
            bottom: 0.2
          }
        },
      },
      columns: [
        { header: 'Item', dataKey: 'index' },
        { header: 'Description', dataKey: 'description' },
        { header: 'Quantity', dataKey: 'quantity' },
        { header: 'Non-Discounted Price', dataKey: 'originalPrice' },
        { header: 'Array Price', dataKey: 'unitPrice' },
        { header: 'Price Total', dataKey: 'total' },
      ],
      body: products,
      foot: [
        [
          {
            content: ' ',
            styles: {
              lineColor: '#2D90FF',
              lineWidth: {
                left: 0.2,
                bottom: 0.2
              }
            }
          },
          {
            content: ' ',
            styles: {
              lineColor: '#2D90FF',
              lineWidth: {
                bottom: 0.2
              }
            }
          },
          {
            content: ' ',
            styles: {
              lineColor: '#2D90FF',
              lineWidth: {
                bottom: 0.2
              }
            }
          },
          {
            content: ' ',
            styles: {
              lineColor: '#2D90FF',
              lineWidth: {
                bottom: 0.2
              }
            }
          },
          {
            content: 'Total',
            styles: {
              lineColor: '#2D90FF',
              lineWidth: {
                bottom: 0.2
              }
            }
          },
          {
            content: totalPrice,
            styles: {
              lineColor: '#2D90FF',
              lineWidth: {
                right: 0.2,
                bottom: 0.2
              }
            }
          },
        ]
      ],
    });
  }

  doc.save(`${jobName} - ${quote.name} - MAS EC Quote.pdf`);
  doc.close();
};

// This function is no longer used:
export const generateSubmittalPDF = ({
  products,
  fanFitData,
  sizings,
}: {
  products: Product[];
  sizings: Control_Panel_Sizing[] | undefined;
  fanFitData?: Fan_Fit[];
}) => {
  const doc = new jsPDF();

  for (let index = 0; index < products.length; index++) {
    const product = products[index];
    const fla = calculateFLA(product.configuration.fla_460, product.voltage_override, product.configuration.min_v_fla, product.configuration.max_v_fla);
    const mocp = calculateMOCPNew({
        fla: fla,
        fanCount: product.configured_fan_count ?? 1,
        voltage: product.voltage_override ?? product.configuration.voltage,
        mas_ec_plus: product.mas_ec_plus,
    });
    const sizing = sizings?.find(
      (c) =>
        c.fan_count === product.configured_fan_count &&
        c.amperage === mocp
    );
    const fanCount = product.configured_fan_count ?? 1;
    const airVolumeEach = product.air_flow / fanCount;

    const rawCoefficients = !!product.configuration?.rmp_coefficients
      ? JSON.parse(product.configuration?.rmp_coefficients) : null;

    let coefficients: RPMCoefficient[] = [];

    if (!!rawCoefficients) {
      const RCKeys = Object.keys(rawCoefficients);
      coefficients = RCKeys.map(k => rawCoefficients[k]);
    }

    const designCoefficients = findDesignCoefficients({
      cfm: product.air_flow,
      tsp: product.total_static_pressure,
      fanCount,
      coefficients,
      model: product.configuration?.model
    });

    const designRPM = calculateOperatingRPM({
      RPMUpperBound: designCoefficients.upper.rpm,
      RPMLowerBound: designCoefficients.lower.rpm,
      Interpolation: designCoefficients.interpolation
    });

    const horsepower = designCoefficients.upper.coefficient?.powerHp && designCoefficients.lower.coefficient?.powerHp ? calculateOperatingInputHorserpower(
      product.air_flow,
      designCoefficients.fanCount || 0,
      designCoefficients.lower.coefficient?.powerHp,
      designCoefficients.upper.coefficient?.powerHp,
      designCoefficients.interpolation
    ) : 0;
    const efficiency = calculateEfficiency({
      fanCount,
      tsp: product.total_static_pressure,
      cfm: product.air_flow,
      horsepower
    });

    const maxRPM = Math.max(...(Object.keys(rawCoefficients).map((k) => parseInt(k))));
    const maxRPMCoefficients = coefficients.find((c: RPMCoefficient) => c.RPM === maxRPM)
    const maxCFM = maxRPMCoefficients
      ? findMaxCFM({
        tsp: product.total_static_pressure,
        coefficient: maxRPMCoefficients.pressure!,
      })
      : 0;

    const redundancy = calculateRedundancy({
      fanCount,
      maxCFM: maxCFM,
      designCFM: product.air_flow,
    });

    const arrayWeight = calculateFanArrayWeight({
      fanCount,
      fanWeight: product.configuration?.fan_weight,
      panelWeight: sizing?.weight || 0,
      bulkheadHeight: product.height || 0,
      bulkheadWidth: product.width || 0,
    });

    const fullLoadCurrent = product.configuration?.fla_460 ?? product.configuration?.fla_400;
    const soundRPM = !!designCoefficients.upper.coefficient && !!designCoefficients.lower.coefficient
      ? calculateRPMSoundPower(fanCount, product.air_flow / fanCount, designCoefficients.lower.coefficient, designCoefficients.upper.coefficient, designCoefficients.interpolation)
      : null;

    const padding = 20;

    const hide63HzRadiatedLabel = product.configuration?.model === "N88-63314";

    doc.setTextColor('#000');
    doc.setFontSize(12);
    doc.setFont('calibri', 'bold');
    doc.text(`Product: ${product.tag}`, 12, 15);

    autoTable(doc, {
      startY: 5 + padding,
      margin: { top: 5, left: 10 },
      theme: 'plain',
      showHead: 'firstPage',
      headStyles: {
        font: 'calibri',
        fillColor: '#1A4061',
        textColor: '#fff',
        fontSize: 10,
      },
      footStyles: {
        font: 'calibri',
        fillColor: '#B8CCE4',
        textColor: '#000',
        lineColor: '#B8CCE4',
        fontSize: 8
      },
      columns: [
        { header: 'ECM Fan Array' },
        { header: 'Component:' },
        { header: 'Length:' },
        { header: 'Shipping Section:' },
      ],
      foot: [
        [
          {
            colSpan: 4,
            content: 'Fan Data',
            styles: {
              halign: 'center',
              lineWidth: {
                bottom: 0.2
              }
            }
          }
        ]
      ],
    });

    autoTable(doc, {
      startY: 22 + padding,
      margin: { top: 5, left: 10 },
      theme: 'plain',
      showHead: 'firstPage',
      headStyles: {
        fillColor: '#D8D8D8',
        textColor: '#000',
        font: 'calibri',
        fontSize: 8,
        fontStyle: 'bold'
      },
      bodyStyles: {
        fillColor: '#F2F2F2',
        halign: 'center',
        font: 'calibri',
        fontSize: 8,
      },
      columns: [
        { header: 'Air Volume (each/total)', dataKey: 'airVolume' },
        { header: 'Total Static Pressure', dataKey: 'totalStaticPressure' },
        { header: 'Quantity of Fans', dataKey: 'fanCount' },
        { header: 'Input Power (each)', dataKey: 'inputPower' },
        { header: 'Operating RPM', dataKey: 'operatingRPM' },
        { header: 'Material', dataKey: 'material' },
        { header: 'Fan Weight (each)', dataKey: 'fanWeight' },
      ],
      body: [
        {
          fanCount,
          airVolume: `${airVolumeEach.toFixed(1)}/${Math.round(product.air_flow ?? 0)} CFM`,
          totalStaticPressure: `${product.total_static_pressure} inWC`,
          inputPower: `${horsepower.toFixed(2)} HP`,
          operatingRPM: `${Math.round(designRPM)} rpm`,
          material: product.configuration?.blade_material ?? '',
          fanWeight: `${Math.round(product.configuration?.fan_weight)} lb`
        }
      ]
    });

    autoTable(doc, {
      startY: 38 + padding,
      margin: { top: 5, left: 10 },
      theme: 'plain',
      showHead: 'firstPage',
      headStyles: {
        fillColor: '#D8D8D8',
        textColor: '#000',
        halign: 'center',
        font: 'calibri',
        fontSize: 8,
        fontStyle: 'bold'
      },
      bodyStyles: {
        fillColor: '#F2F2F2',
        halign: 'center',
        font: 'calibri',
        fontSize: 8,
      },
      footStyles: {
        fillColor: '#B8CCE4',
        textColor: '#000',
        lineColor: '#B8CCE4',
        halign: 'center',
        font: 'calibri',
        fontSize: 8,
        fontStyle: 'bold'
      },
      columns: [
        { header: 'Fan Model', dataKey: 'model' },
        { header: 'Efficiency', dataKey: 'efficiency' },
        { header: 'Redundancy', dataKey: 'redundancy' },
        { header: 'Array Weight', dataKey: 'arrayWeight' },
      ],
      body: [
        {
          model: `${product.configuration?.model}`,
          efficiency: `${(efficiency * 100).toFixed(2)} %`,
          redundancy: `${Math.round(Math.max(0.01, (redundancy * 100)))} %`,
          arrayWeight: `${Math.round(arrayWeight)} lb`,
        }
      ],
      foot: [
        [
          {
            colSpan: 4,
            content: 'Motor/Electrical Data',
            styles: {
              halign: 'center',
              lineWidth: {
                bottom: 0.2
              }
            }
          }
        ]
      ],
    });

    autoTable(doc, {
      startY: 61 + padding,
      margin: { top: 5, left: 10 },
      theme: 'plain',
      showHead: 'firstPage',
      headStyles: {
        fillColor: '#D8D8D8',
        textColor: '#000',
        halign: 'center',
        font: 'calibri',
        fontSize: 8,
        fontStyle: 'bold'
      },
      bodyStyles: {
        fillColor: '#F2F2F2',
        halign: 'center',
        font: 'calibri',
        fontSize: 8,
      },
      footStyles: {
        fillColor: '#B8CCE4',
        textColor: '#000',
        lineColor: '#B8CCE4',
        font: 'calibri',
        fontSize: 8,
        fontStyle: 'bold'
      },
      columns: [
        { header: 'Motor Horsepower (each)', dataKey: 'motorHorsepower' },
        { header: 'Maximum RPM', dataKey: 'maximumRPM' },
        { header: 'Electrical Supply', dataKey: 'electricalSupply' },
        { header: 'Full Load Current (each)', dataKey: 'fullLoadCurrent' },
        { header: 'MCA', dataKey: 'mca' },
        { header: 'MOCP', dataKey: 'mocp' },
      ],
      body: [
        {
          motorHorsepower: `${product.configuration?.nominal_hp} HP`,
          maximumRPM: `${maxRPM} rpm`,
          electricalSupply: `${product.configuration?.voltage} V/3ph`,
          fullLoadCurrent: `${fullLoadCurrent} A`,
          mca: `${calculateMCANew({ fla, fanCount, voltage: product.voltage_override ?? product.configuration.voltage, mas_ec_plus: product.mas_ec_plus, }).toFixed(2)} A`,
          mocp: `${Math.round(mocp)} A`,
        }
      ],
      foot: [
        [
          {
            colSpan: 6,
            content: 'Fan Options',
            styles: {
              halign: 'center',
              lineWidth: {
                bottom: 0.2
              }
            }
          }
        ]
      ],
    });

    autoTable(doc, {
      startY: 84 + padding,
      margin: { top: 5, left: 10, right: 106 },
      showHead: false,
      styles: { overflow: 'hidden' },
      columnStyles: {
        label: { fillColor: '#D8D8D8', textColor: '#000', fontStyle: 'bold', halign: 'center', font: 'calibri', fontSize: 8 },
      },
      bodyStyles: {
        halign: 'center',
        font: 'calibri',
        fillColor: '#F2F2F2',
        fontSize: 8
      },
      body: [
        {
          label: 'Blank-Off Plate:',
          data: product.backdraft_dampers ? 'None' : 'Included',
        },
        {
          label: 'Fan Panel Disconnect:',
          data: '100kAIC Fused Disconnect',
        },
      ],
    })

    autoTable(doc, {
      startY: 84 + padding,
      margin: { left: 106 },
      showHead: false,
      styles: { overflow: 'hidden' },
      columnStyles: {
        label: { fillColor: '#D8D8D8', textColor: '#000', fontStyle: 'bold', halign: 'center', font: 'calibri', fontSize: 8 },
      },
      bodyStyles: {
        halign: 'center',
        font: 'calibri',
        fillColor: '#F2F2F2',
        fontSize: 8
      },
      body: [
        {
          label: 'Isolation Backdraft Dampers:',
          data: product.backdraft_dampers ? 'Included' : 'None'
        },
        {
          label: 'Control Input:',
          data: '0-10 VDC',
        },
      ],
    })

    doc.setFontSize(10);
    doc.setFont('helvetica', 'bold');

    doc.setFillColor(26, 64, 97);
    // doc.rect(10, 210 + padding, 186, 8, 'F');

    doc.setTextColor('#fff');
    doc.setFont('calibri', 'bold');
    doc.text('Array Sound Power (dBA)', 12, 215 + padding);

    autoTable(doc, {
      startY: 220 + padding,
      margin: { top: 5, left: 10 },
      headStyles: {
        fillColor: '#D8D8D8',
        textColor: '#000',
        halign: 'center',
        font: 'calibri',
        fontStyle: 'bold',
        fontSize: 8
      },
      columnStyles: {
        type: { fillColor: '#D8D8D8', textColor: '#000', fontStyle: 'bold', halign: 'center', font: 'calibri', fontSize: 8 },
      },
      bodyStyles: {
        fillColor: '#F2F2F2',
        halign: 'center',
        font: 'calibri',
        fontSize: 8,
      },
      columns: [
        { header: 'Type', dataKey: 'type' },
        { header: '63 Hz', dataKey: 'hz63' },
        { header: '125 Hz', dataKey: 'hz125' },
        { header: '250 Hz', dataKey: 'hz250' },
        { header: '500 Hz', dataKey: 'hz500' },
        { header: '1000 Hz', dataKey: 'hz1000' },
        { header: '2000 Hz', dataKey: 'hz2000' },
        { header: '4000 Hz', dataKey: 'hz4000' },
        { header: '8000 Hz', dataKey: 'hz8000' },
      ],
      body: [
        {
          type: 'Radiated: ',
          hz63: `${hide63HzRadiatedLabel ? "-" : Math.round(soundRPM?.hz63.radiated ?? 0)}`,
          hz125: `${Math.round(soundRPM?.hz125.radiated ?? 0)}`,
          hz250: `${Math.round(soundRPM?.hz250.radiated ?? 0)}`,
          hz500: `${Math.round(soundRPM?.hz500.radiated ?? 0)}`,
          hz1000: `${Math.round(soundRPM?.hz1000.radiated ?? 0)}`,
          hz2000: `${Math.round(soundRPM?.hz2000.radiated ?? 0)}`,
          hz4000: `${Math.round(soundRPM?.hz4000.radiated ?? 0)}`,
          hz8000: `${Math.round(soundRPM?.hz8000.radiated ?? 0)}`
        },
        {
          type: 'Unit Discharge: ',
          hz63: `${hide63HzRadiatedLabel ? "-" : Math.round(soundRPM?.hz63.unitDischarge ?? 0)}`,
          hz125: `${Math.round(soundRPM?.hz125.unitDischarge ?? 0)}`,
          hz250: `${Math.round(soundRPM?.hz250.unitDischarge ?? 0)}`,
          hz500: `${Math.round(soundRPM?.hz500.unitDischarge ?? 0)}`,
          hz1000: `${Math.round(soundRPM?.hz1000.unitDischarge ?? 0)}`,
          hz2000: `${Math.round(soundRPM?.hz2000.unitDischarge ?? 0)}`,
          hz4000: `${Math.round(soundRPM?.hz4000.unitDischarge ?? 0)}`,
          hz8000: `${Math.round(soundRPM?.hz8000.unitDischarge ?? 0)}`
        },
        {
          type: 'Unit Return: ',
          hz63: `${hide63HzRadiatedLabel ? "-" : Math.round(soundRPM?.hz63.unitReturn ?? 0)}`,
          hz125: `${Math.round(soundRPM?.hz125.unitReturn ?? 0)}`,
          hz250: `${Math.round(soundRPM?.hz250.unitReturn ?? 0)}`,
          hz500: `${Math.round(soundRPM?.hz500.unitReturn ?? 0)}`,
          hz1000: `${Math.round(soundRPM?.hz1000.unitReturn ?? 0)}`,
          hz2000: `${Math.round(soundRPM?.hz2000.unitReturn ?? 0)}`,
          hz4000: `${Math.round(soundRPM?.hz4000.unitReturn ?? 0)}`,
          hz8000: `${Math.round(soundRPM?.hz8000.unitReturn ?? 0)}`
        }
      ]
    });

    const rpmCurves = coefficients.length
      ? coefficients.map(c => generateRPMCurve({ fanCount, rpmCoefficient: c }))
      : [];

    const designPoints = 25;
    const designRPMCurve = !!designRPM
      ? generateRPMCurve({
        fanCount,
        rpmCoefficient: {
          RPM: Math.round(designRPM),
          pressure: designCoefficients.coefficient,
        } as RPMCoefficient,
        points: designPoints,
        dashed: true
      })
      : null;

    const systemCurve = maxRPMCoefficients ? generateSystemCurve({
      cfm: product.air_flow,
      tsp: product.total_static_pressure,
      fanCount,
      points: designPoints,
      maxRpmPressureCoefficients: maxRPMCoefficients.pressure
    }) : null;

    const sets = [...rpmCurves];

    if (designRPMCurve) sets.push(designRPMCurve);
    if (systemCurve) sets.push(systemCurve as any);

    const annotation = { annotations: {} };

    const designPoint = maxRPMCoefficients && systemCurve && findDesignPoint(designCoefficients.coefficient, systemCurve.coefficients, product.air_flow, product.total_static_pressure, fanCount);

    if (!!designPoint) {
      const annotations = annotation.annotations;

      (annotations as any).designPoint = {
        type: 'point',
        backgroundColor: '#1890cb',
        radius: 5,
        xValue: designPoint.x,
        yValue: designPoint.y
      };
    }

    const fanCurve = drawFanCurveChart(sets, annotation);

    doc.addImage(fanCurve.image, 'JPEG', 10, 100 + padding, 190, 100);
    fanCurve.chart.clear();

    if ((index + 1) < products.length) doc.addPage();
  }

  doc.save(`ECM Fan Array.pdf`);
  doc.close();
};

const pdfGenerators = {
  generateQuotePDF,
  generateSubmittalPDF
};

export default pdfGenerators;